import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
} from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TextEditor } from "./textFunctions";
import { ImageSelector } from "./textFunctions";
import { fetchUploadImage, getToken } from "utils";

interface ContentDialogProps {
  table: string;
  len: number;
  open: boolean;
  onClose: () => void;
  onSave: (part: any) => void;
  initialData?: any | null;
}

export const ContentDialog: React.FC<ContentDialogProps> = ({
  table,
  len,
  open,
  onClose,
  onSave,
  initialData,
}) => {
  const [part, setPart] = useState(
    initialData || { type: "text", data: { text: "", style: {} } }
  );
  const [files, setFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>(
    initialData?.data?.images || []
  );
  const token = getToken();
  useEffect(() => {
    if (initialData) {
      setPart(initialData);
      setPreviewUrls(initialData.data?.images || []);
    }
  }, [initialData]);

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPart({
      ...part,
      type: event.target.value as string,
      data: { text: "", style: {} },
    });
  };

  const handleDataChange = (value: any) => {
    setPart({ ...part, data: value });
  };

  const handleFileChange = (
    files: File[] | null,
    previewUrls: string[] | null
  ) => {
    setFiles(files || []);
    setPreviewUrls(previewUrls || []);
  };

  const handleSave = async () => {
    let imageUrls = previewUrls;
    if (files.length > 0 && token) {
      const uploadPromises = files.map((file) =>
        fetchUploadImage(
          file,
          `images/pages/${table}/${file.name}_${table}_${part.type}_${len+1}`,
          token,
          "/private/images",
          `imagesUrl/pages/${table}/${file.name}_${table}_${part.type}_${len+1}`
        )
      );
      const uploadedImages = await Promise.all(uploadPromises);
      imageUrls = uploadedImages.map((img) => img.url);
    }
    let updatedData = part.data;
    if (
      part.type === "image" ||
      part.type === "textWithImageLeft" ||
      part.type === "textWithImageRight"
    ) {
      updatedData = { ...updatedData, images: imageUrls };
    }
    onSave({ ...part, data: updatedData });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {part.id ? "Edit Content Part" : "Add New Content Part"}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <InputLabel>Type</InputLabel>
          <Select value={part.type} onChange={handleTypeChange as any}>
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="image">Image</MenuItem>
            <MenuItem value="textWithImageLeft">Text with Image Left</MenuItem>
            <MenuItem value="textWithImageRight">
              Text with Image Right
            </MenuItem>
            <MenuItem value="video">Video</MenuItem>
            <MenuItem value="link">Link</MenuItem>
          </Select>
        </FormControl>
        {part.type === "text" && (
          <div>
            <TextEditor data={part.data} onChange={handleDataChange} />
          </div>
        )}
        {part.type === "image" && (
          <ImageSelector
            table={table}
            len={len}
            type={part.type}
            initialUrls={previewUrls}
            onFileChange={handleFileChange}
          />
        )}
        {(part.type === "textWithImageLeft" ||
          part.type === "textWithImageRight") && (
          <Box>
            <CKEditor
              editor={ClassicEditor}
              data={part.data.text || ""}
              onChange={(_, editor) =>
                handleDataChange({ text: editor.getData() })
              }
            />
            <ImageSelector
              table={table}
              len={len}
              type={part.type}
              initialUrls={previewUrls}
              onFileChange={handleFileChange}
            />
          </Box>
        )}
        {part.type === "video" && (
          <TextField
            label="Video URL"
            value={part.data || ""}
            onChange={(e) => handleDataChange(e.target.value)}
            fullWidth
            margin="dense"
          />
        )}
        {part.type === "link" && (
          <TextField
            label="Link URL"
            value={part.data || ""}
            onChange={(e) => handleDataChange(e.target.value)}
            fullWidth
            margin="dense"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
