import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  TableSortLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Flag from 'react-flagkit';
import { ProductType, GiftCardType } from '../../../utils';
import { deleteGiftCard } from '../../../utils';

interface AllGiftCardsTableProps {
  giftCards: GiftCardType[];
  products: ProductType[];
  refreshGiftCards: () => void;
  setCurrentGiftCard: (giftCard: GiftCardType | null) => void;
  setOpenDialog: (open: boolean) => void;
}

const AllGiftCardsTable: React.FC<AllGiftCardsTableProps> = ({
  giftCards,
  products,
  refreshGiftCards,
  setCurrentGiftCard,
  setOpenDialog,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(40);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof GiftCardType>("code");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof GiftCardType
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEdit = (giftCard: GiftCardType) => {
    setCurrentGiftCard(giftCard);
    setOpenDialog(true);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteGiftCard(id);
      refreshGiftCards();
    } catch (error) {
      console.error("Failed to delete gift card:", error);
    }
  };

  const sortedGiftCards = (
    cards: GiftCardType[],
    comparator: (a: GiftCardType, b: GiftCardType) => number
  ) => {
    const stabilizedThis = cards.map(
      (el, index) => [el, index] as [GiftCardType, number]
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (
    order: "asc" | "desc",
    orderBy: keyof GiftCardType
  ): ((a: GiftCardType, b: GiftCardType) => number) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (
    a: GiftCardType,
    b: GiftCardType,
    orderBy: keyof GiftCardType
  ) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getProductNameById = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product
      ? `${product.name} ${product.price} ${product.currency}`
      : "Unknown Product";
  };

  const getCountryCode = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.countryCode : "Unknown Product";
  };

  const getCategory = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.category : "Unknown Product";
  };

  const getProvider = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.provider : "Unknown Product";
  };

  const getProductSellingPrice = (
    productId: string,
    products: ProductType[]
  ) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.priceUSD : "0";
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Typography variant="h6">All Gift Cards</Typography>
      <Table>
        <TableHead>
          <TableRow>
            {[
               { id: "fullname", label: "FullName" },
              { id: "category", label: "Category" },
              { id: "provider", label: "Provider" },
              { id: "productId", label: "Product" },
              { id: "contrycode", label: "Country" },
              { id: "Selling", label: "Selling Price" },
              { id: "cost", label: "Coût" },
              { id: "sold", label: "Sold" },
              { id: "code", label: "Code" },
            ].map((headCell) => (
              <TableCell
                key={headCell.id}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={(event) =>
                    handleRequestSort(event, headCell.id as keyof GiftCardType)
                  }
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className="visually-hidden">
                      {order === "desc" ? "sorted descending" : "sorted ascending"}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedGiftCards(giftCards, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((card) => (
              <TableRow key={card.id}>
                 <TableCell>{card.fullname}</TableCell>
                <TableCell>{getCategory(card.productId, products)}</TableCell>
                <TableCell>{getProvider(card.productId, products)}</TableCell>
                <TableCell>{getProductNameById(card.productId, products)}</TableCell>
                <TableCell>
                  <Flag country={getCountryCode(card.productId, products)} />
                </TableCell>
                <TableCell>{getProductSellingPrice(card.productId, products)} $</TableCell>
                <TableCell>{card.cost} $</TableCell>
                <TableCell>{card.sold ? "Yes" : "No"}</TableCell>
                <TableCell>{card.code}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(card)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(card.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 40, 50, 100]}
        component="div"
        count={giftCards.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default AllGiftCardsTable;
