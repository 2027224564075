import { getUserByID } from "..";

export const actionCodeSettings = {
  url: "http://localhost:3000/finishSignUp",
  handleCodeInApp: true,
};

export const getUserData = async (userId: string) => {
  return await getUserByID(userId);
};

export const getUserName = async (userId: string): Promise<string | null> => {
  const userData = await getUserData(userId);
  return userData?.username || null;
};

export const getUserEmail = async (userId: string): Promise<string | null> => {
  const userData = await getUserData(userId);
  return userData?.email || null;
};

export const getUserAge = async (userId: string): Promise<number | null> => {
  const userData = await getUserData(userId);
  return userData?.age || null;
};

export const getUserRole = async (userId: string): Promise<string | null> => {
  const userData = await getUserData(userId);
  return userData?.role || null;
};

export const getUserAmount = async (userId: string): Promise<number | null> => {
  const userData = await getUserData(userId);
  return userData?.amount || null;
};

export const getUserConfirmationStatus = async (
  userId: string
): Promise<boolean | null> => {
  const userData = await getUserData(userId);
  return userData?.isConfirmed || null;
};

