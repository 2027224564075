import {
  FinishSignUp,
  Home,
  Login,
  NotAuthorized,
  Registration,
  AdminPanel,
  Dashboard,
  ResetPassword,
  Manager,
  ProductManagement,
  UserManagement,
  UploadComponent,
  StepList,
  MottoList,
  ProductsPages,
  ProviderProductsPage,
  ProviderManager,
  GiftCardTable,
  PredefinedListsManager,
  MainLayout,
  AccountSection,
  OrdersSection,
  KeyLibrarySection,
  BalanceSection,
  TransactionSuccess,
  PromoCodeManager,
  RichText,
  CartSummary,
  About,
  Contact,
  Transaction,
  Prices,
  SettingsSection,
} from "../../components/index";
import { RouteObject } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { CategoryPage } from "components/shared/product/CategoryPage";
import { ResendVerificationEmail } from "components/auth/ResendVerificationEmail ";
import { DataDeletionInstructions } from "components/auth/facebook";
import { SmsMessagesTable } from "components/manager/SmsMessageTable";

const descriptionText: string =
  "Vous disposez de suffisamment d'autorisations pour accéder à cette page";

export const Paths: RouteObject[] = [
  { path: "/", element: <Home /> },
  { path: "/about", element: <About /> },
  { path: "/contacts", element: <Contact /> },
  { path: "/prix", element: <Prices /> },
  { path: "/login", element: <Login /> },
  { path: "/signUp", element: <Registration /> },
  { path: "/finishSignUp", element: <FinishSignUp /> },
  {
    path: "/not-authorized",
    element: <NotAuthorized textInfo={descriptionText} />,
  },
  { path: "/confirmer-email", element: <ResendVerificationEmail /> },
  { path: "/data-deletion-instructions", element: <DataDeletionInstructions /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/products", element: <ProductsPages /> },
  { path: "/products/:category", element: <CategoryPage /> },
  { path: "/provider/:provider", element: <ProviderProductsPage /> },
  {
    path: "/dashboard",
    element: <PrivateRoute component={Dashboard} roles={["admin", "user"]} />,
  },
  {
    path: "/manager/sms",
    element: <PrivateRoute component={SmsMessagesTable} roles={["admin"]} />,
  },
  {
    path: "/cart-summary",
    element: (
      <PrivateRoute
        component={CartSummary}
        roles={["admin", "user", "manager"]}
      />
    ),
  },
  {
    path: "/transaction-success",
    element: (
      <PrivateRoute
        component={TransactionSuccess}
        roles={["admin", "user", "manager"]}
      />
    ),
  },
  {
    path: "/transaction",
    element: (
      <PrivateRoute
        component={Transaction}
        roles={["admin", "user", "manager"]}
      />
    ),
  },
  {
    path: "/manager",
    element: <PrivateRoute component={Manager} roles={["admin", "manager"]} />,
  },
  {
    path: "/manager/product",
    element: <PrivateRoute component={ProductManagement} roles={["admin"]} />,
  },
  {
    path: "/manager/images",
    element: <PrivateRoute component={UploadComponent} roles={["admin"]} />,
  },
  {
    path: "/manager/users",
    element: <PrivateRoute component={UserManagement} roles={["admin"]} />,
  },
  {
    path: "/manager/steps",
    element: <PrivateRoute component={StepList} roles={["admin"]} />,
  },
  {
    path: "/manager/mottos",
    element: <PrivateRoute component={MottoList} roles={["admin"]} />,
  },
  {
    path: "/manager/providers",
    element: <PrivateRoute component={ProviderManager} roles={["admin"]} />,
  },
  {
    path: "/manager/giftcard",
    element: <PrivateRoute component={GiftCardTable} roles={["admin"]} />,
  },
  {
    path: "/manager/list",
    element: (
      <PrivateRoute component={PredefinedListsManager} roles={["admin"]} />
    ),
  },
  {
    path: "/manager/PromoCodeManager",
    element: <PrivateRoute component={PromoCodeManager} roles={["admin"]} />,
  },
  {
    path: "/manager/rich-text",
    element: <PrivateRoute component={RichText} roles={["admin"]} />,
  },
  {
    path: "/admin",
    element: <PrivateRoute component={AdminPanel} roles={["admin"]} />,
  },
  {
    path: "/account/*",
    element: (
      <PrivateRoute
        component={MainLayout}
        roles={["admin", "user", "manager"]}
      />
    ),
    children: [
      { path: "account", element: <AccountSection /> },
      { path: "balance", element: <BalanceSection /> },
      { path: "orders", element: <OrdersSection /> },
      { path: "key-library", element: <KeyLibrarySection /> },
      { path: "settings", element: <SettingsSection /> },
    ],
  },
];
