import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { motion } from "framer-motion";
interface ImageListProps {
  itemData: string[];
  width:any
}
export const ImagesList: React.FC<ImageListProps> = ({ itemData,width }) => {
  return (
    <motion.div
    className="flex w-full items-center justify-center">
      <ImageList
        sx={{ width: width, height: 450 }}
        variant="woven"
        cols={3}
        gap={8}
      >
        {itemData.map((item, index) => (
          <ImageListItem key={index}>
            <img
              srcSet={`${item}?w=161&fit=crop&auto=format&dpr=2 2x`}
              src={`${item}?w=161&fit=crop&auto=format`}
              alt={`${item}+${index}`}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </motion.div>
  );
};
