import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
  Box,
  TableSortLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import {
  ProductType,
  useSecurityCheck,
  rolesAdmin,
  fetchPublic,
  deleteProductOnTheServer,
  SetFlag,
} from "../../../utils";
import { Loading } from "../../shared";
import { ProductDialog } from "./ProductDialog";

const headCells = [
  { id: "name", numeric: false, label: "Name" },
  { id: "provider", numeric: true, label: "Provider" },
  { id: "category", numeric: false, label: "Category" },
  { id: "price", numeric: true, label: "Price" },
  { id: "currency", numeric: false, label: "Currency" },
  { id: "country", numeric: false, label: "Country" },
  { id: "countrFlag", numeric: false, label: "CountryFlag" },
  { id: "priceUSD", numeric: true, label: "Price USD" },
  { id: "bestseller", numeric: true, label: "Bestseller" },
  { id: "actions", numeric: false, label: "Actions" },
];

export const ProductTable: React.FC = () => {
  const [products, setProducts] = useState<ProductType[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof ProductType>("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(40);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<ProductType | null>(
    null
  );
  const securityCheck = useSecurityCheck(rolesAdmin);
  useEffect(() => {
    if (!securityCheck) {
      console.log("Checking");
      fetchProducts();
    }
  }, [securityCheck]);
  const fetchProducts = async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/product`);
    if (result.products) {
      const productsArray = Object.values(result.products) as ProductType[];
      setProducts(productsArray);
    }
    setLoading(false);
  };

  const handleRequestSort = (property: keyof ProductType) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (id: string) => {
    console.log(id);
    await deleteProductOnTheServer(id);
    fetchProducts();
  };

  const handleEdit = (product: ProductType) => {
    setCurrentProduct(product);
    setOpenDialog(true);
  };

  const handleAdd = () => {
    setCurrentProduct(null);
    setOpenDialog(true);
  };
  const sortedProducts = products.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: "95%", marginTop: "55px" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Produits
          </Typography>
          <Button
            variant="contained"
            sx={{ backgroundColor: "#133B62" }}
            onClick={handleAdd}
          >
            Ajouter un produit
          </Button>
        </Toolbar>
        <TableContainer>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="400px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() =>
                          handleRequestSort(headCell.id as keyof ProductType)
                        }
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((product) => (
                    <TableRow hover tabIndex={-1} key={product.id}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.provider}</TableCell>
                      <TableCell>{product.category}</TableCell>
                      <TableCell>{product.price}</TableCell>
                      <TableCell>{product.currency}</TableCell>
                      <TableCell>{product.country}</TableCell>
                      <TableCell>
                        {" "}
                        <SetFlag
                          countryCode={product.countryCode}
                          countryName={product.country}
                        />{" "}
                      </TableCell>
                      <TableCell>{product.priceUSD} $</TableCell>
                      <TableCell>
                        {product.isBestseller ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit">
                          <IconButton onClick={() => handleEdit(product)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleDelete(product.id)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[40, 50, 100]}
          component="div"
          count={products.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ProductDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={fetchProducts}
        initialData={currentProduct}
      />
    </Box>
  );
};
