import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@mui/material';
import { ProductType } from '../../../utils';

interface GroupedGiftCardsTableProps {
  groupedGiftCards: any[];
  products: ProductType[];
}

const GroupedGiftCardsTable: React.FC<GroupedGiftCardsTableProps> = ({
  groupedGiftCards,
  products,
}) => {
  const [pageGrouped, setPageGrouped] = useState(0);
  const [rowsPerPageGrouped, setRowsPerPageGrouped] = useState(40);

  const handleChangePageGrouped = (event: unknown, newPage: number) => {
    setPageGrouped(newPage);
  };

  const handleChangeRowsPerPageGrouped = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPageGrouped(+event.target.value);
    setPageGrouped(0);
  };

  const getProductNameById = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product
      ? `${product.name} ${product.price} ${product.currency}`
      : "Unknown Product";
  };

  const getProductSellingPrice = (productId: string, products: ProductType[]) => {
    const product = products.find((prod) => prod.id === productId);
    return product ? product.priceUSD : "0";
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Typography variant="h6">Grouped Gift Cards</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Selling price</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Quantity Sold</TableCell>
            <TableCell>Quantity Not Sold</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedGiftCards
            .slice(
              pageGrouped * rowsPerPageGrouped,
              pageGrouped * rowsPerPageGrouped + rowsPerPageGrouped
            )
            .map((card, index) => (
              <TableRow key={index}>
                <TableCell>{getProductNameById(card.productId, products)}</TableCell>
                <TableCell>{getProductSellingPrice(card.productId, products)}</TableCell>
                <TableCell>{card.quantity}</TableCell>
                <TableCell>{card.quantitySold}</TableCell>
                <TableCell>{card.quantityNotSold}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 40, 50, 100]}
        component="div"
        count={groupedGiftCards.length}
        rowsPerPage={rowsPerPageGrouped}
        page={pageGrouped}
        onPageChange={handleChangePageGrouped}
        onRowsPerPageChange={handleChangeRowsPerPageGrouped}
      />
    </TableContainer>
  );
};

export default GroupedGiftCardsTable;
