import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useUtility } from "../../../utils/context/UtilityContext";
import { Header } from "../Header";
import { SetFlag } from "utils";

export const CartSummary: React.FC = () => {
  const { cart, removeFromCart, updateCartQuantity, marginBottomHeader } =
    useUtility();
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const calculateTotal = useCallback(() => {
    const totalCost = cart.reduce(
      (acc, item) => acc + item.product.priceUSD * item.quantity,
      0
    );
    setTotal(totalCost);
  }, [cart]);

  useEffect(() => {
    calculateTotal();
  }, [cart, calculateTotal]);

  const handleQuantityChange = (index: number, quantity: number) => {
    const productId = cart[index].product.id;
    updateCartQuantity(productId, quantity);
    calculateTotal();
  };

  const handleRemoveItem = (index: number) => {
    const productId = cart[index].product.id;
    removeFromCart(productId);
    calculateTotal();
  };

  /*const handleCheckout = () => {
    navigate("/transaction");
  };*/

  const handleBuyNow = (index: number) => {
    const productToBuy = cart[index];
    navigate("/transaction", { state: { productToBuy } });
  };

  return (
    <div
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <Header />
      <Box p={4}>
        <Typography variant="h4" gutterBottom>
          Résumé du panier
        </Typography>
        <Typography variant="body2" gutterBottom>
          Vous pouvez acheter une grande quantité d'un même produit, mais un
          seul type de produit à la fois.
        </Typography>
        {cart.map((item, index) => (
          <Card key={index} sx={{ display: "flex", marginBottom: 2 }}>
            <div className="flex p-2 w-1/5">
              <CardMedia
                component="img"
                sx={{
                  borderRadius: "8px",
                  objectFit: "contain",
                  width: "100%",
                }}
                image={item.product.image}
                alt={item.product.name}
              />
            </div>

            <Box
              sx={{ display: "flex", flexDirection: "column", width: "80%" }}
            >
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  {item.product.name} {item.product.country}{" "}
                  <SetFlag
                    countryCode={item.product.countryCode}
                    countryName={item.product.country}
                  />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Valeur : {item.product.price} {item.product.currency}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  Prix : {item.product.priceUSD} BSB Coins
                </Typography>
                <TextField
                  label="Quantité"
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: 1 }}
                  value={item.quantity}
                  onChange={(e) =>
                    handleQuantityChange(index, parseInt(e.target.value))
                  }
                  sx={{ width: "100px", marginTop: 2 }}
                />
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pl: 1,
                  pb: 1,
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ margin: 3 }}
                  onClick={() => handleBuyNow(index)}
                >
                  Acheter maintenant
                </Button>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveItem(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </Card>
        ))}
        <Typography variant="h5" gutterBottom>
          Total: {total} {cart.length > 0 ? "BSB Coins" : "BSB Coins"}
        </Typography>
      </Box>
    </div>
  );
};
