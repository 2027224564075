import Slider from "react-slick";
import { Box } from "@mui/material";
import { fetchImages, SlideshowImage } from "../../utils";
import { useEffect, useState } from "react";

export const Slideshow = () => {
  const [slideShowImages, setSlideShowImages] = useState<SlideshowImage[]>([]);

  useEffect(() => {
    const getImages = async () => {
      try {
        const images = await fetchImages(`/public/images/slideshow`);
        setSlideShowImages(images);
      } catch (error) {
        console.error('Failed to fetch slideshow images', error);
      }
    };
    getImages();
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden", boxSizing: "border-box" }}>
      <Slider {...settings}>
        {slideShowImages.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              width: "100%",
              height: "300px",
              overflow: "hidden",
            }}
          >
            <img
              src={image.url}
              alt={`slide-${index}`}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};