import React, { useEffect, useState } from 'react';
import { Typography, Box, Divider, Paper, Avatar, Grid } from '@mui/material';
import { useAuth } from '../../utils/context/authContext';
import { getUserByID, useWindowSize } from '../../utils'; // Ensure you have this path correct
import { User } from '../../utils/types'; // Ensure you have this type defined
import { Loading } from '../shared';
import { Cancel, CheckCircle } from '@mui/icons-material';

export const AccountSection: React.FC = () => {
  const { user: authUser } = useAuth();
  const [user, setUser] = useState<User | null>(null);
  const { isMobile } = useWindowSize();
  useEffect(() => {
    const fetchUserData = async () => {
      if (authUser) {
        const fetchedUser = await getUserByID(authUser.userID);
        setUser(fetchedUser);
      }
    };
    fetchUserData();
  }, [authUser]);

  if (!user) {
    return <Loading />;
  }

  return (
    <Box p={3} width={'100%'} overflow={'hidden'}>
      <Typography variant="h5" gutterBottom>Vue d'ensemble</Typography>
      <Divider sx={{ my: 2 }} />

      <Paper elevation={3} sx={{ p: 2, mb: 3,width:isMobile?"100%":"100%"}} >
        <Typography variant="h6" gutterBottom>Profil</Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={2}>
            <Avatar alt={user.username} src={user.avatarUrl} sx={{ width: 56, height: 56 }} />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Typography variant="body1">{user.username}</Typography>
            <Typography variant="body2" noWrap>{user.email}</Typography>
            <Typography variant="body1">{user.age} ans</Typography>
            <Typography variant="body2">{user.gender}</Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Solde Total</Typography>
        <Typography variant="h4" color="primary">{user.amount.toFixed(2)} $US</Typography>
        <Typography variant="body2">Montant total de tous les soldes estimé sur la base du taux de conversion le plus récent.</Typography>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Solde Carte-Cadeau</Typography>
        <Typography variant="body2">À dépenser sur BSB seulement</Typography>
        <Typography variant="h6">{user.amount.toFixed(2)} $US</Typography>
      </Paper>

      <Paper elevation={3} sx={{ p: 2, mb: 3, background: user?.isConfirmed ? '#cef6ec' : '#f8d7da' }}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" gutterBottom>
            Vérification du compte
          </Typography>
        </Box>
        <Typography variant="h5">
          {user?.isConfirmed ? (
            <CheckCircle sx={{ color: '#28a745', mr: 1 }} />
          ) : (
            <Cancel sx={{ color: '#dc3545', mr: 1 }} />
          )}
          {user?.isConfirmed ? "Actif" : "Inactif"}
        </Typography>
      </Paper>
    </Box>
  );
};
