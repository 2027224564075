import React, { useEffect, useState } from "react";
import {
  auth,
  signInWithPopup,
  googleProvider,
  facebookProvider,
} from "../../utils/firebase/firebaseConfig";
import logo from "../../assets/Bsb.png";
import { useNavigate } from "react-router-dom";
import {
  AppleIcon,
  FacebookIcon,
  fetchFacebookSignIn,
  fetchGoogleSignIn,
  fetchLogin,
  GoogleIcon,
  useAuthListener,
  User,
  useWindowSize,
} from "../../utils";
import { useAuth } from "../../utils/context";
import { motion } from "framer-motion";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const Login: React.FC = () => {
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [userCredential, setUserCredential] = useState<User | null>(null);
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
  const { isPC } = useWindowSize();
  const [token, setToken] = useState(null);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  useAuthListener() 
  useEffect(() => {
    const handleLogin = async () => {
      if (userCredential && isLoginSuccessful) {
        login(userCredential);
        console.log("Successfully logged in!",userCredential);
        if (!userCredential.isConfirmed) {
          // Si l'email n'est pas vérifié, affichez la boîte de dialogue
          setMessage(
            "Votre email n'est pas vérifié. Veuillez vérifier votre email ou continuer."
          );
          setOpen(true);
        } else {
          // Si l'email est vérifié, naviguez vers la page d'accueil
          navigate("/");
        }
      }
    };

    handleLogin();
  }, [userCredential, isLoginSuccessful, navigate, login, token]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    const result = await fetchLogin({ email, password });
    if(result){
      if (result.success) {
        if (result && result.user) {
          setUserCredential(result.user);
          setIsLoginSuccessful(true);
        }
  
        localStorage.setItem("token", result.token);
        setToken(result.token);
      } else {
        setError(result.message);
      }
    }
   
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
     
      const user = auth.currentUser;
      console.log(user)
      if (user) {
        const token = await user.getIdToken();
        const response = await fetchGoogleSignIn(token,result);

        if (response.success) {
          const { user } = response;
          login(user);
          localStorage.setItem("token", response.token);
          navigate("/");
        } else {
          console.error("Authentication failed:", response.message);
        }
      }
    } catch (error: any) {
      console.error("Error during Google sign-in:", error.message);
    }
  };


  const handleFacebookSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, facebookProvider);

      const user = auth.currentUser;
      console.log(user);
      if (user) {
        const token = await user.getIdToken();
        
        const response = await fetchFacebookSignIn(token, result); 

        if (response.success) {
          const { user } = response;
          login(user);
          localStorage.setItem("token", response.token);
          navigate("/");
        } else {
          console.error("Authentication failed:", response.message);
        }
      }
    } catch (error: any) {
      console.error("Error during Facebook sign-in:", error.message);
    }
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleClose = () => {
    setOpen(false);
    navigate("/"); // Continuer vers la page d'accueil
  };

  const handleVerify = () => {
    setOpen(false);
    navigate("/confirmer-email"); // Naviguer vers la page de vérification
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex h-screen w-screen justify-center items-center"
    >
      <div
        className={`flex justify-center items-center ${
          isPC
            ? "flex-row h-5/6 w-4/6 border-2 rounded-lg"
            : "flex-col w-full h-full overflow-scroll overflow-x-hidden pb-8"
        } `}
      >
        {/* Left Side    */}
        <div
          className={`bg-gray-200 flex items-center justify-center ${
            isPC ? "w-2/5 h-full" : "w-full h-1/6"
          } `}
        >
          <img
            src={logo}
            alt="Logo"
            className={`${isPC ? "w-2/3" : "h-full"}`}
          />
        </div>
        {/* Right Side   */}
        <div
          className={` flex items-center justify-center  flex-col ${
            isPC ? "w-3/5 p-8 pb-16 h-full overflow-scroll overflow-x-hidden" : "h-5/6 "
          }`}
        >
          <div className="w-full max-w-md h-full">
            <h2 className="text-3xl font-bold mb-4 text-center">Connexion</h2>
            <p className="mb-4 text-center">Bon retour</p>
            <div className="w-full flex gap-2 items-center justify-center">
              <div className="w-20 h-20 flex items-center justify-center border-2 py-2 m-4 rounded-full hover:bg-[#e5e7eb] transition-colors cursor-pointer m-5 shrink-0">
                <FacebookIcon  onClick={handleFacebookSignIn} className="w-8 h-8 shrink-0" />
              </div>
              <div
                onClick={handleGoogleSignIn}
                className="w-20 h-20 flex items-center justify-center border-2 py-2 m-4 rounded-full hover:bg-[#e5e7eb] transition-colors cursor-pointer m-5 shrink-0"
              >
                <GoogleIcon className={isPC ? "w-8 h-8" : "w-8 h-8"} />{" "}
              </div>
              <div className="w-20 h-20 flex items-center justify-center border-2 py-2 m-4 rounded-full hover:bg-[#e5e7eb] transition-colors cursor-pointer m-5 shrink-0">
                <AppleIcon className="w-8 h-8" />
              </div>
            </div>

            <div className={` border-2 my-5 w-full  ${isPC ? " " : ""}`} />
            <form onSubmit={handleLogin} className={`${isPC ? "" : "p-8"}`}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="mb-6 relative">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    placeholder="Password"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition-colors"
              >
                Se connecter
              </button>
              {error && <p className="mt-4 text-red-500">{error}</p>}
            </form>
            <p className="mt-4 text-center">
              Mot de passe oublié?{" "}
              <a
                href="/reset-password"
                className="text-blue-500 hover:underline"
              >
                Réinitialisez-le
              </a>
            </p>
            <p className="mt-4 text-center">
              Pas encore inscrit?{" "}
              <a href="/signup" className="text-blue-500 hover:underline">
                S'inscrire
              </a>
            </p>
          </div>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{"Email non vérifié"}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVerify} color="primary">
            Vérifier l'email
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Continuer vers l'accueil
          </Button>
        </DialogActions>
      </Dialog>
    </motion.div>
  );
};
