import { useState, useEffect } from "react";
import { fetchPageContent, postPageContent } from "utils/api";

export const useGetTables = () => {
  const [tables, setTables] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTables = async () => {
      try {
        const result = await fetchPageContent(''); 
        if (result.success && result.data) {
          const tableNames = Object.keys(result.data);
          setTables(tableNames);
        } else {
          setError("No data available");
        }
      } catch (error) {
        console.error("Error fetching tables:", error);
        setError("Failed to fetch table names.");
      } finally {
        setLoading(false);
      }
    };

    fetchTables();
  }, []);

  const addTable = async (tableName: string) => {
    try {
      await postPageContent(tableName, {}); // Creating an empty page as a new table
      setTables((prevTables) => [...prevTables, tableName]);
    } catch (error) {
      console.error("Error adding table:", error);
      setError("Failed to add table.");
    }
  };

  return { tables, loading, error, addTable };
};