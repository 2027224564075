import React from "react";
import { Link } from "react-router-dom";

export const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800">404 - Page Not Found</h1>
      <p className="mt-4 text-lg text-gray-600">
        Désolé, la page que vous recherchez n'existe pas.
      </p>
      <p className="mt-2 text-lg text-gray-600">
        Vous pouvez toujours revenir à la{" "}
        <Link to="/" className="text-blue-500 hover:underline">
          a la page d'accueil
        </Link>
        .
      </p>
    </div>
  );
};
