import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Box,
  Avatar,
  Link,
} from "@mui/material";
import Flag from "react-flagkit";
import { ProductType, useWindowSize } from "utils";
import KeyIcon from "@mui/icons-material/Key";
import VpnLockIcon from "@mui/icons-material/VpnLock";
import DevicesOtherIcon from "@mui/icons-material/DevicesOther";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

interface ConfirmationDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  product: ProductType | null;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  product,
}) => {
  const [confirmedPlatform, setConfirmedPlatform] = React.useState(false);
  const [confirmedRegion, setConfirmedRegion] = React.useState(false);
  const [agreed, setAgreed] = React.useState(false);
  const { isPC } = useWindowSize();
  React.useEffect(() => {
    setConfirmedPlatform(false);
    setConfirmedRegion(false);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <div className="bg-variant_1 text-white">
        <DialogTitle>Confirmation de la région et de la plateforme</DialogTitle>
        <DialogContent>
          <Typography gutterBottom component={"h5"}>
            Veuillez vous assurer que vous avez acheté le bon produit numérique,
            car les retours sont impossibles une fois que le produit a été
            affiché.
          </Typography>
          {product && (
            <Box
              display={"flex"}
              gap={5}
              margin={5}
              
            
              alignItems={"center"}
              justifyContent={"center"}
              flexWrap={"wrap"}
            >
              <Box
                width={"100%"}
                display={"flex"}
                alignItems={isPC ? "center" : "flex-start"}
                justifyContent={isPC ? "space-between" : "flex-start"}
                flexDirection={isPC ? "row" : "column"}
                gap={5}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <KeyIcon fontSize="large" />
                  <div>
                    <Typography variant="subtitle1" noWrap>
                      Produit:
                    </Typography>
                    <Typography variant="h6" noWrap>
                      Cle numerique
                    </Typography>
                  </div>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <Avatar src={product.image} alt={product.name} />
                  <div>
                    <Typography variant="subtitle1" noWrap>
                      Carte cadeau:
                    </Typography>
                    <Typography variant="h6">
                      {product.name} {product.price} {product.currency}
                    </Typography>
                  </div>
                </Box>
              </Box>

              <Box
                width={"100%"}
                display={"flex"}
                flexDirection={isPC ? "row" : "column"}
                alignItems={isPC ? "center" : "flex-start"}
                justifyContent={isPC ? "space-between" : "flex-start"}
                gap={5}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <VpnLockIcon fontSize="large" />
                  <div>
                    <Typography variant="subtitle1" noWrap>
                      Region:
                    </Typography>
                    <Typography variant="h6" noWrap>
                      <div className="flex items-center justify-center gap-4">
                        {product.country} <Flag country={product.countryCode} />
                      </div>
                    </Typography>
                  </div>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <DevicesOtherIcon fontSize="large" />
                  <div>
                    <Typography variant="subtitle1" noWrap>
                      Platforme:
                    </Typography>
                    <Typography variant="h6" noWrap>
                      {product.provider}
                    </Typography>
                  </div>
                </Box>
              </Box>

              <Box
                width={"100%"}
                display={"flex"}
                alignItems={isPC ? "center" : "flex-start"}
                justifyContent={isPC ? "center" : "flex-start"}
                flexDirection={isPC ? "row" : "column"}
               
                gap={5}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  gap={2}
                >
                  <AttachMoneyIcon fontSize="large" />
                  <div>
                    <Typography variant="subtitle1" noWrap>
                      Prix:
                    </Typography>
                    <Typography variant="h6" noWrap>
                      <div className="flex items-center justify-center gap-4">
                        {product.priceUSD} BSB Coins
                      </div>
                    </Typography>
                  </div>
                </Box>
              </Box>
            </Box>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmedPlatform}
                onChange={(e) => setConfirmedPlatform(e.target.checked)}
                name="confirmPlatform"
                color="primary"
              />
            }
            label={`Ma platforme est la bonne`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmedRegion}
                onChange={(e) => setConfirmedRegion(e.target.checked)}
                name="confirmRegion"
                color="primary"
              />
            }
            label={`${product?.country.toUpperCase()} est la bonne région`}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                name="agree"
                color="primary"
              />
            }
            label={
              <span>
                J'accepte les termes et conditions.{" "}
                <Link
                  href="https://termify.io/privacy-policy/1Zqcow40ea"
                  underline="hover"
                >
                  Privacy Policy
                </Link>{" "}
                |{" "}
                <Link
                  href="https://termify.io/terms-and-conditions/3aprKAyl8I"
                  underline="hover"
                >
                  Terms
                </Link>
              </span>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Annuler
          </Button>
          <Button
            onClick={onConfirm}
            sx={{ color: "white", background: "#6E8A9D" }}
            disabled={!confirmedPlatform || !confirmedRegion || !agreed}
          >
            Confirmer le paiement
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
