import React from "react";
import { TextDisplay, VideoDisplay } from "./textFunctions";

interface ContentPartType {
  id?: string;
  type: string;
  data: any;
}

interface ContentPartProps {
  part: ContentPartType;
}

export const ContentPartPreview: React.FC<ContentPartProps> = ({ part }) => {
  switch (part.type) {
    case "text":
      console.log(part.data);
      return (
        <TextDisplay
          text={`${part.data.text.slice(0, 250)} ...`}
          color={part.data.style?.color || "black"}
          fontSize={part.data.style?.fontSize || "16px"}
          fontFamily={part.data.style?.fontFamily || "Arial"}
          width={part.data.style?.width || "100%"}
          textAlign={part.data.style?.textAlign || "left"}
          lineHeight={part.data.style?.lineHeight || "1.5"}
          letterSpacing={part.data.style?.letterSpacing || "normal"}
          fontWeight={part.data.style?.fontWeight || "normal"}
        />
      );
    case "image":
      return <img src={part.data.images} alt="Content" style={{ width: "50%" }} />;
    case "textWithImageLeft":
      return (
        <div style={{ display: "flex" }}>
          <img src={part.data.image} alt="Content" style={{ width: "50%" }} />
          <div
            dangerouslySetInnerHTML={{ __html: part.data.text }}
            style={{ width: "50%" }}
          />
        </div>
      );
    case "textWithImageRight":
      return (
        <div style={{ display: "flex" }}>
          <div
            dangerouslySetInnerHTML={{ __html: part.data.text }}
            style={{ width: "50%" }}
          />
          <img src={part.data.image} alt="Content" style={{ width: "50%" }} />
        </div>
      );
    case "video":
      return <VideoDisplay videoLink={part.data} />;
    case "link":
      return (
        <a href={part.data} target="_blank" rel="noopener noreferrer">
          {part.data}
        </a>
      );
    default:
      return null;
  }
};
