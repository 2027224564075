import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import RenderRoutes from "./utils/navigation/Navigation";
import { AnimatePresence } from "framer-motion";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  if (!clientId) throw Error("clientID is undefined");
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Router>
        <AnimatePresence>
          <RenderRoutes />
        </AnimatePresence>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
