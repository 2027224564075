import { motion } from "framer-motion";
import {
  CartIcon,
  CategoriesMenu,
  bsbLogoWithoutName,
  getUserRole,
} from "../../../utils";
import { useAuth } from "../../../utils/context";
import { Badge } from "@mui/material";
import { SearchBar } from "../searchBar";
import { handleSearch, menuItems } from "./Header";
import { DropdownMenu } from "../DropdownMenu";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useUtility } from "../../../utils/context/UtilityContext";
import { UserAvatarMenu } from "./UserAvatarMenu";
import { CartDialog } from "./CartDialog";

interface HeaderPcProps {
  secondPart?: boolean;
}

export const HeaderPc: React.FC<HeaderPcProps> = ({ secondPart = true }) => {
  const { user } = useAuth();
  const [role, setRole] = React.useState<string | null>(null);
  const { cart } = useUtility();
  const [cartOpen, setCartOpen] = useState(false);

  React.useEffect(() => {
    if (user) {
      (async () => {
        const userRole = await getUserRole(user.userID);
        setRole(userRole);
      })();
    }
  }, [user]);

  const handleCartOpen = () => {
    setCartOpen(true);
  };

  const handleCartClose = () => {
    setCartOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex flex-col h-32 fixed top-0 left-0 w-full z-50"
    >
      <div className=" shadow-md bg-white  h-16 p-4">
        <div className=" mx-auto flex justify-between items-center h-full ">
          <div className="flex items-center h-full">
            <img
              src={bsbLogoWithoutName}
              className="h-10 w-auto mr-4"
              alt="bsbLogo"
            />
            <nav className="h-full ">
              <ul className="flex items-center space-x-4 h-full">
                {menuItems}
                {role && (role === "admin" || role === "manager") && (
                  <li className="px-2">
                    <Link
                      to={"/manager"}
                      className="text-black hover:text-gray-300"
                    >
                      Gestion
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>
          <div className="h-full flex items-center">
            <UserAvatarMenu />
          </div>
        </div>
      </div>
      {secondPart && (
        <div className=" bg-primary shadow-md mx-auto flex justify-between items-center h-20 px-4  w-full text-white">
          <DropdownMenu menus={CategoriesMenu} />

          <SearchBar onSearch={handleSearch} />
          <Badge
            badgeContent={cart.length}
            color="primary"
            onClick={handleCartOpen}
          >
            <CartIcon style={{ fill: "white" }} />
          </Badge>
        </div>
      )}

      <CartDialog open={cartOpen} onClose={handleCartClose} />
    </motion.div>
  );
};
