import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  Avatar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useUtility } from "../../../utils/context/UtilityContext";
import {
  postUserDetails,
  ProductType,
  SetFlag,
  TransactionTypeError,
  useWindowSize,
} from "../../../utils";
import { useAuth } from "../../../utils/context/authContext";
import { CustomSnackbar, Header, MessageDialog } from "../../shared";
import { ConfirmationDialog } from "./ConfirmationDialog"; // Ensure to import the new component

export const Transaction: React.FC = () => {
  const { cart, removeFromCart, updateCartQuantity, marginBottomHeader } =
    useUtility();
  const { isPC } = useWindowSize();
  const { user, login } = useAuth();
  const [total, setTotal] = useState(0);
  const [totalWithoutDiscount, setTotalWithoutDiscount] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);
  const [isPromoCodeUsed, SetIsPromoCodeUsed] = useState(false);
  const [promoCodeType, SetPromoCodeType] = useState("");
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductType | null>(
    null
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [messageDialog, setMessageDialog] = useState("");
  const [selectedErrorProduct, setSelectedErrorProduct] = useState<any>(null);
  const navigate = useNavigate();
  const userFetchedRef = useRef(false);
  const [indexCart, setIndexCart] = useState(-1);
  const handleCloseDialog = () => setDialogOpen(false);
  const [type, setType] = useState<TransactionTypeError>("OTHER");

  const fetchUser = useCallback(async () => {
    if (user && user.userID && !userFetchedRef.current) {
      login(user);
      userFetchedRef.current = true;
    }
  }, [user, login]);

  const calculateTotal = useCallback(() => {
    const totalCost = cart.reduce(
      (acc, item) => acc + item.product.priceUSD * item.quantity,
      0
    );
    setTotal(totalCost - discount);
    setTotalWithoutDiscount(totalCost);
  }, [cart, discount]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    calculateTotal();
  }, [cart, discount, calculateTotal]);

  const handleQuantityChange = (index: number, quantity: number) => {
    const productId = cart[index].product.id;
    updateCartQuantity(productId, quantity);
    calculateTotal();
  };

  const handleRemoveItem = (index: number) => {
    const productId = cart[index].product.id;
    removeFromCart(productId);
    calculateTotal();
  };

  const handlePromoCodeApply = async () => {
    try {
      if (!user) {
        setSnackbarMessage("User is null");
        setSnackbarSuccess(false);
        setSnackbarOpen(true);
        return;
      }
      const response = await postUserDetails(
        { promoCode, totalWithoutDiscount },
        "promocode-apply",
        user.userID
      );

      if (response.error) {
        setSnackbarMessage(response.error);
        setSnackbarSuccess(false);
      } else {
        setDiscount(response.discount);
        setSnackbarMessage("Le code promo a été appliqué avec succès !");
        setSnackbarSuccess(true);
        SetIsPromoCodeUsed(true);
        SetPromoCodeType(response.type);
      }
    } catch (error) {
      setSnackbarMessage("Erreur lors de l'application du code promo.");
      setSnackbarSuccess(false);
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleRemoveDiscount = () => {
    setDiscount(0);
    setPromoCode("");
    setSnackbarMessage("Le code promo a été retiré.");
    setSnackbarSuccess(true);
    setSnackbarOpen(true);
  };

  const handleProceed = async () => {
    if (!user) return;

    if (
      user.amount <
      cart[indexCart].quantity * cart[indexCart].product.priceUSD
    ) {
      setSnackbarMessage(
        "Fonds insuffisants. Veuillez contacter le support pour recharger votre compte."
      );
      setType("SOLDE");
      setMessageDialog(
        "Fonds insuffisants. Veuillez contacter le support pour recharger votre compte."
      );
      setSelectedErrorProduct({
        name: cart[indexCart].product.name,
        price: cart[indexCart].product.price,
        currency: cart[indexCart].product.currency,
        country: cart[indexCart].product.country,
        quantity: cart[indexCart].quantity,
      });
      setDialogOpen(true);
      setSnackbarSuccess(false);
      setSnackbarOpen(true);
      return;
    }

    try {
      const totalTransaction =
        cart[indexCart].quantity * cart[indexCart].product.priceUSD;
      const item = cart[indexCart];
      const response = await postUserDetails(
        { item, totalTransaction, promoCode, promoCodeType, isPromoCodeUsed },
        "proceed-transaction",
        user.userID
      );

      if (response.error) {
        console.log(response.error);
        setSnackbarMessage(response.error);
        setSnackbarSuccess(false);
      } else {
        handleRemoveItem(indexCart);
        navigate("/transaction-success", {
          state: { transactionTimestamp: response.transactionTimestamp },
        });
        setSnackbarMessage("Transaction terminée avec succès !");
        setSnackbarSuccess(true);
      }
    } catch (error: any) {
      if (error.message.includes(`Stock épuisé`)) {
        console.log("check 1");
        setType("STOCK");
        setMessageDialog(error.message);
        setSelectedErrorProduct({
          name: cart[indexCart].product.name,
          price: cart[indexCart].product.price,
          currency: cart[indexCart].product.currency,
          country: cart[indexCart].product.country,
          quantity: cart[indexCart].quantity,
        });
        setDialogOpen(true);
      }
      setSnackbarMessage(error.message || "Erreur lors de la transaction.");
      setSnackbarSuccess(false);
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleOpenConfirmationDialog = (
    product: ProductType,
    index: number
  ) => {
    setIndexCart(index);
    setSelectedProduct(product);
    setConfirmationDialogOpen(true);
  };

  const handleConfirmDialogClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmDialogProceed = () => {
    setConfirmationDialogOpen(false);
    handleProceed();
  };

  return (
    <Box
      p={4}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      padding={isPC ? "5%" : "1px"}
    >
      <Header secondPart={false} />
      <div
        className={`${marginBottomHeader} ${
          isPC ? "px-8  w-4/5 border-4" : "p-8 w-full"
        }`}
      >
        <Typography variant="h4" gutterBottom>
          Résumé de la Transaction
        </Typography>
        {cart.map((item, index) => (
          <Card
            key={index}
            sx={{
              display: "flex",

              marginBottom: 2,
              width: "100%",
              border: "solid 1px",
              padding: "4px",
            }}
          >
            <div className="w-1/5 justify-center items-center flex p-8">
              <Avatar
                src={item.product.image}
                alt={item.product.name}
                sx={{ width: isPC ? 100 : 80, height: isPC ? 100 : 80 }}
              />
            </div>
            <div className="w-4/5 justify-center items-center flex p-4">
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    gap: "5%",
                    padding: "2px",
                    flexDirection: "column",
                  }}
                >
                  <Typography component="div" variant={isPC ? "h5" : "body1"}>
                    {item.product.name} {item.product.price}{" "}
                    {item.product.currency} {item.product.country}{" "}
                    <SetFlag
                      countryCode={item.product.countryCode}
                      countryName={item.product.country}
                    />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    {item.product.priceUSD} BSB Coins
                  </Typography>
                  <TextField
                    label="Quantité"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ min: 1 }}
                    value={item.quantity}
                    onChange={(e) =>
                      handleQuantityChange(index, parseInt(e.target.value))
                    }
                    sx={{ width: "100px", marginTop: 2 }}
                  />
                </CardContent>
                <Box
                  sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1, justifyContent:'center' }}
                >
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemoveItem(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleOpenConfirmationDialog(item.product, index)
                    }
                  >
                    Acheter Maintenant
                  </Button>
                </Box>
              </Box>
            </div>
          </Card>
        ))}
        <TextField
          label="Code Promo"
          fullWidth
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <div className="w-full flex justify-center items-center  gap-4">
          <Button
            variant="contained"
            color="primary"
            onClick={handlePromoCodeApply}
          >
            Appliquer le Code Promo
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRemoveDiscount}
            sx={{ marginLeft: 2 }}
          >
            Retirer le Code Promo
          </Button>
        </div>

        <Typography variant="h5" gutterBottom>
          Total: {total} {"BSB Coins"}
        </Typography>
        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          isSuccess={snackbarSuccess}
          handleClose={handleSnackbarClose}
        />
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={handleConfirmDialogClose}
          onConfirm={handleConfirmDialogProceed}
          product={selectedProduct}
        />

        {user && (
          <MessageDialog
            open={dialogOpen}
            handleClose={handleCloseDialog}
            message={messageDialog}
            item={selectedErrorProduct}
            user={user}
            type={type}
          />
        )}
      </div>
    </Box>
  );
};
