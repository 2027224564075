import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { Footer, Header } from "../../shared";
import { deleteInfo, fetchPublic, MottoType, postInfo, putInfo, rolesAdmin, useSecurityCheck } from "../../../utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { motion } from "framer-motion";
import { CustomSnackbar } from "../../shared";

export const MottoList = () => {
  const [mottos, setMottos] = useState<MottoType[]>([]);
  const securityCheck = useSecurityCheck(rolesAdmin);

  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentMotto, setCurrentMotto] = useState<MottoType | null>(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

 

  const fetchMottos = useCallback(async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/info/mottos`);
    if (result.info) {
      setMottos(Object.values(result.info));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchMottos();
  }, [fetchMottos]);

  const handleSaveMotto = async (motto: MottoType) => {
    setLoading(true);
    try {
      if (motto.id) {
        await putInfo(motto,'mottos');
      } else {
        await postInfo(motto,'mottos');
      }
      setSnackbarMessage('Motto sauvegardée avec succès!');
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage('Erreur lors de la sauvegarde du motto');
      setSnackbarSuccess(false);
    } finally {
      fetchMottos();
      setOpenDialog(false);
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleDeleteMotto = async (id: string) => {
    setLoading(true);
    try {
      await deleteInfo(id,'mottos');
      setSnackbarMessage('Motto supprimé avec succès!');
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage('Erreur lors de la suppression du motto');
      setSnackbarSuccess(false);
    } finally {
      fetchMottos();
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (securityCheck) {
    return securityCheck;
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={` flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <Box sx={{ width: "100%", paddingLeft: '10%', paddingRight: '10%' }}>
        <Header />
        <Typography variant="h4" gutterBottom marginTop={"10%"}>
          Liste des Mottos
        </Typography>
        <Button
          sx={{ marginLeft: "5px" }}
          variant="contained"
          color="primary"
          onClick={() => setOpenDialog(true)}
        >
          Ajouter un nouveau motto
        </Button>
        <List>
          {mottos.map((motto) => (
            <div key={motto.id}>
              <ListItem>
                <ListItemText primary={motto.motto} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      setCurrentMotto(motto);
                      setOpenDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    onClick={() => handleDeleteMotto(motto.id!)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </div>
          ))}
        </List>
        <MottoDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setCurrentMotto(null);
          }}
          onSave={handleSaveMotto}
          initialData={currentMotto}
        />
      </Box>
      <Footer />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </motion.div>
  );
};

interface MottoDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (motto: MottoType) => void;
  initialData?: MottoType | null;
}

const MottoDialog: React.FC<MottoDialogProps> = ({
  open,
  onClose,
  onSave,
  initialData,
}) => {
  const [motto, setMotto] = useState<MottoType>(initialData || { motto: "" });

  useEffect(() => {
    if (initialData) {
      setMotto(initialData);
    } else {
      setMotto({ motto: "" });
    }
  }, [initialData]);

  const handleChange = (value: string) => {
    setMotto({ ...motto, motto: value });
  };

  const handleSave = () => {
    onSave(motto);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth // Enable full width
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "90%", // Adjust the dialog width
          width: "100%", // Make the dialog width 100%
          height: "80vh", // Adjust the dialog height
        },
      }}
    >
      <DialogTitle>{motto.id ? "Modifier le Motto" : "Ajouter un Motto"}</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactQuill value={motto.motto} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleSave} color="primary">
          Sauvegarder
        </Button>
      </DialogActions>
    </Dialog>
  );
};