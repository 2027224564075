import React from 'react';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';

interface ProviderListProps {
    providers: string[];
    providerImages: { provider: string, image: string }[];
    onSelectProvider: (provider: string) => void;
  }
  
  export const ProviderList: React.FC<ProviderListProps> = ({ providers, providerImages, onSelectProvider }) => {
    return (
      <Grid container spacing={2} justifyContent="center">
        {providers.map((provider, index) => {
          const providerImage = providerImages.find(p => p.provider === provider)?.image;
          return (
            <Grid item key={index}>
              <Card onClick={() => onSelectProvider(provider)} sx={{ cursor: 'pointer', maxWidth: 200 }}>
                <CardContent>
                  {providerImage && (
                    <CardMedia
                      component="img"
                      image={providerImage}
                      alt={provider}
                      sx={{ height: 140, objectFit: "contain", margin: "10px 0" }}
                    />
                  )}
                  <Typography variant="h6" textAlign="center">
                    {provider}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };
