import { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { CustomSnackbar } from "../../shared";
import { postInfo, putInfo, deleteInfo, fetchPublic } from "../../../utils";

export const PredefinedListsManager = () => {
  const [open, setOpen] = useState(false);
  const [currentList, setCurrentList] = useState<any[]>([]);
  const [newItem, setNewItem] = useState("");
  const [listType, setListType] = useState("category");
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

  const fetchLists = useCallback(async () => {
    setLoading(true);
    try {
      const result = await fetchPublic(`/public/info/predifine/${listType}`);
      if (result.info) {
        setCurrentList(Object.values(result.info));
      } else {
        setCurrentList([]);
      }
    } catch (error) {
      console.error("Error fetching lists:", error);
      setCurrentList([]);
    } finally {
      setLoading(false);
    }
  }, [listType]);

  useEffect(() => {
    fetchLists();
  }, [listType, fetchLists]);

  const handleSaveItem = async () => {
    try {
      const newItemData = { id: Date.now().toString(), name: newItem };
      if (newItemData.id) {
        await putInfo(newItemData, `predifine/${listType}`);
      } else {
        await postInfo(newItemData, `predifine/${listType}`);
      }
      setNewItem("");
      fetchLists();
      setSnackbarMessage(`${listType} ajouté avec succès!`);
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage(`Erreur lors de l'ajout du ${listType}`);
      setSnackbarSuccess(false);
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleDeleteItem = async (id: string) => {
    try {
      await deleteInfo(id, `predifine/${listType}`);
      fetchLists();
      setSnackbarMessage(`${listType} supprimé avec succès!`);
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage(`Erreur lors de la suppression du ${listType}`);
      setSnackbarSuccess(false);
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleOpenDialog = async (type: string) => {
    setListType(type);
    setLoading(true);
    await fetchLists();
    setLoading(false);
    setOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box>
      <Typography variant="h4">Gérer les listes prédéfinies</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog("category")}
      >
        Gérer les catégories
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog("provider")}
        sx={{ marginLeft: "10px" }}
      >
        Gérer les fournisseurs
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog("name")}
        sx={{ marginLeft: "10px" }}
      >
        Gérer les noms
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Gérer {listType}</DialogTitle>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TextField
                label={`Nouveau ${listType}`}
                value={newItem}
                onChange={(e) => setNewItem(e.target.value)}
                fullWidth
                margin="normal"
              />
              <Button onClick={handleSaveItem} color="primary">
                Sauvegarder
              </Button>
              <List>
                {currentList.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={item.name} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={() => handleDeleteItem(item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Fermer</Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </Box>
  );
};