import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { Footer, Header } from "../../shared";
import {
  deleteInfo,
  fetchPublic,
  postInfo,
  putInfo,
  rolesAdmin,
  StepType,
  useSecurityCheck,
} from "../../../utils";
import { motion } from "framer-motion";
import { CustomSnackbar } from "../../shared";

export const StepList = () => {
  const [steps, setSteps] = useState<StepType[]>([]);
  const securityCheck = useSecurityCheck(rolesAdmin);

  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentStep, setCurrentStep] = useState<StepType | null>(null);
  const [expandedStep, setExpandedStep] = useState<string | null>(null);


  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);



  const fetchSteps = useCallback(async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/info/steps`);
    if (result.info) {
      setSteps(Object.values(result.info));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchSteps();
  }, [fetchSteps]);

  const handleSaveStep = async (step: StepType) => {
    setLoading(true);
    try {
      if (step.id) {
        await putInfo(step, "steps");
      } else {
        await postInfo(step, "steps");
      }
      setSnackbarMessage("Étape sauvegardée avec succès!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Erreur lors de la sauvegarde de l'étape");
      setSnackbarSuccess(false);
    } finally {
      fetchSteps();
      setOpenDialog(false);
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleDeleteStep = async (id: string) => {
    setLoading(true);
    try {
      await deleteInfo(id, "steps");
      setSnackbarMessage("Étape supprimée avec succès!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Erreur lors de la suppression de l'étape");
      setSnackbarSuccess(false);
    } finally {
      fetchSteps();
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleExpandStep = (step: string) => {
    setExpandedStep(expandedStep === step ? null : step);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (securityCheck) {
    return securityCheck;
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={` flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <div className={`w-full p-16`}>
        <Box sx={{ width: "100%" }}>
          <Header />
          <Typography variant="h4" gutterBottom marginTop={"10%"}>
            Liste des Étapes
          </Typography>
          <Button
            sx={{ marginLeft: "5px" }}
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Ajouter une nouvelle étape
          </Button>
          <List>
            {steps.map((step) => (
              <div key={step.id}>
                <ListItem button onClick={() => handleExpandStep(step.step)}>
                  <ListItemText primary={step.step} />
                  {expandedStep === step.step ? <ExpandLess /> : <ExpandMore />}
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setCurrentStep(step);
                        setOpenDialog(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={() => handleDeleteStep(step.id!)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse
                  in={expandedStep === step.step}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box sx={{ marginLeft: 4 }}>
                    <Typography variant="body2" noWrap>
                      {step.description}
                    </Typography>
                  </Box>
                </Collapse>
              </div>
            ))}
          </List>
          <StepDialog
            open={openDialog}
            onClose={() => {
              setOpenDialog(false);
              setCurrentStep(null);
            }}
            onSave={handleSaveStep}
            initialData={currentStep}
          />
        </Box>
      </div>
      <Footer />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </motion.div>
  );
};

interface StepDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (step: StepType) => void;
  initialData?: StepType | null;
}

const StepDialog: React.FC<StepDialogProps> = ({
  open,
  onClose,
  onSave,
  initialData,
}) => {
  const [step, setStep] = useState<StepType>(
    initialData || { step: "", description: "" }
  );

  useEffect(() => {
    if (initialData) {
      setStep(initialData);
    } else {
      setStep({ step: "", description: "" });
    }
  }, [initialData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStep({ ...step, [name]: value });
  };

  const handleSave = () => {
    onSave(step);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {step.id ? "Modifier l'Étape" : "Ajouter une Étape"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Étape"
          name="step"
          value={step.step}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          name="description"
          value={step.description}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleSave} color="primary">
          Sauvegarder
        </Button>
      </DialogActions>
    </Dialog>
  );
};