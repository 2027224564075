import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { fetchImages, SlideshowImage } from "utils";

export const BSBCoin = () => {
  const [coin, setCoin] = useState<SlideshowImage[]>([]);

  useEffect(() => {
    const getImages = async () => {
      try {
        const images = await fetchImages(`/public/images/coin`);
        setCoin(images);
      } catch (error) {
        console.error("Failed to fetch slideshow images", error);
      }
    };
    getImages();
  }, []);
  return (
    <Box>
      {coin.map((image, index) => (
        <Tooltip title="BSBCoin" placement="bottom-end" key={index}>
          <img
            src={image.url}
            alt={`BSB coin-${index}`}
            loading="lazy"
            height="80px"
            width="80px"
          />
        </Tooltip>
      ))}
    </Box>
  );
};
