import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { getProducts, GiftCardType, ProductType } from "../../../utils";
import { CustomSnackbar } from "../../shared";

const initialGiftCardState: GiftCardType = {
  id: "",
  fullname:"",
  productId: "",
  cost: "",
  code: "",
  sold: false,
  isActive: true,
};

interface AdminGiftCardEntryProps {
  open: boolean;
  onClose: () => void;
  onSave: (giftCard: GiftCardType) => void;
  currentGiftCard?: GiftCardType | null;
}

export const AdminGiftCardEntry: React.FC<AdminGiftCardEntryProps> = ({
  open,
  onClose,
  onSave,
  currentGiftCard,
}) => {
  const [giftCard, setGiftCard] = useState(initialGiftCardState);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage] = useState("");
  const [snackbarSuccess] = useState(true);

  useEffect(() => {
    if (currentGiftCard) {
      setGiftCard(currentGiftCard);
    } else {
      setGiftCard(initialGiftCardState);
    }
    fetchProducts();
  }, [currentGiftCard]);

  const fetchProducts = async () => {
    const prods = await getProducts();
    setProducts(prods);
    setLoading(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setGiftCard((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    onSave(giftCard);
  };

  const handleProductSelect = (productId: string) => {
    setGiftCard((prevState) => ({
      ...prevState,
      productId,
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {currentGiftCard
          ? "Modifier la carte-cadeau"
          : "Ajouter une carte-cadeau"}
      </DialogTitle>
      <DialogContent>
      <TextField
          margin="dense"
          label="Nom de la carte & valeur"
          type="text"
          fullWidth
          name="fullname"
          value={giftCard.fullname}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          label="Code de la carte-cadeau"
          type="text"
          fullWidth
          name="code"
          value={giftCard.code}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          label="Coût en $"
          type="text"
          fullWidth
          name="cost"
          value={giftCard.cost}
          onChange={handleInputChange}
        />
        <Typography variant="h6" gutterBottom>
          Sélectionner le produit
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Nom</TableCell>
                <TableCell>Prix</TableCell>
                <TableCell>Devise</TableCell>
                <TableCell>Pays</TableCell>
                <TableCell>Sélectionner</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>{product.name}</TableCell>
                  <TableCell>{product.price}</TableCell>
                  <TableCell>{product.currency}</TableCell>
                  <TableCell>{product.country}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color={
                        product.id === giftCard.productId
                          ? "success"
                          : "primary"
                      }
                      onClick={() => handleProductSelect(product.id)}
                    >
                      {product.id === giftCard.productId
                        ? "Sélectionné"
                        : "Sélectionner"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleSave} color="primary">
          Sauvegarder
        </Button>
      </DialogActions>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </Dialog>
  );
};
