import React from "react";
import { Route, Routes, useLocation } from "react-router";
import { Paths } from "./Routes"; // Adjust path if necessary
import { NotFound } from "components";

const RenderRoutes: React.FC = () => {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      {Paths.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
        <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default RenderRoutes;
