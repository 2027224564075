import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  signInWithPopup,
  auth,
  googleProvider,
  fetchGoogleSignIn,
  fetchSignUp,
  useWindowSize,
} from "../../utils";
import logo from "../../assets/Bsb.png";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../../utils";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { motion } from "framer-motion";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../utils/context";

export const Registration: React.FC = () => {
  const { isPC } = useWindowSize();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas");
      return;
    }

    setLoading(true); // Set loading state to true when request starts

    const result = await fetchSignUp(email, password, username, age, gender);

    setLoading(false); // Set loading state to false when request finishes

    if (result.success) {
      if (result.token) localStorage.setItem("token", result.token);
      setMessage("Email de vérification envoyé !");
      navigate("/finishSignUp");
    } else {
      setError(
        result.message || "Une erreur est survenue lors de l'inscription"
      );
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true); // Set loading state to true when request starts
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      if (user) {
        const token = await user.getIdToken();
        const response = await fetchGoogleSignIn(token,result);

        if (response.success) {
          const { user } = response;
          login(user);
          localStorage.setItem("token", response.token);
          navigate("/");
        } else {
          console.error("Authentication failed:", response.message);
        }
      }
    } catch (error: any) {
      console.error("Error during Google sign-in:", error.message);
    } finally {
      setLoading(false); // Set loading state to false when request finishes
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex h-screen justify-center items-center"
    >
      <div
        className={`flex justify-center items-center rounded-lg  ${
          isPC
            ? "flex-row h-5/6 w-4/6 border-2 "
            : "flex-col w-full h-full overflow-scroll overflow-x-hidden pb-8"
        } `}
      >
        {/* Left Side */}
        <div
          className={`bg-gray-200 flex items-center justify-center ${
            isPC ? "w-2/5 h-full" : "w-full h-1/6"
          } `}
        >
          <img
            src={logo}
            alt="Logo"
            className={`${isPC ? "w-2/3" : "h-full"}`}
          />
        </div>
        {/* Right Side */}
        <div
          className={` flex items-center justify-center  flex-col ${
            isPC
              ? "w-3/5 p-8 pb-16 overflow-scroll overflow-x-hidden h-full scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
              : "h-5/6 "
          }`}
        >
          <div className="w-full max-w-md h-full">
            <h2 className="text-3xl font-bold mb-4 text-center">Inscription</h2>
            <p className="mb-4 text-center">Bienvenue</p>

            <div className="w-full flex gap-5 items-center justify-center ">
              <div className="w-20 h-20 flex items-center justify-center border-2 py-2 mt-4 rounded-full hover:bg-[#e5e7eb] transition-colors cursor-pointer m-5 shrink-0">
                <FacebookIcon className="w-12 h-12" />
              </div>
              <div
                onClick={handleGoogleSignIn}
                className="w-20 h-20 flex items-center justify-center border-2 py-2 mt-4 rounded-full hover:bg-[#e5e7eb] transition-colors cursor-pointer m-5 shrink-0"
              >
                <GoogleIcon className="w-12 h-12" />
              </div>
              <div className="w-20 h-20 flex items-center justify-center border-2 py-2 mt-4 rounded-full hover:bg-[#e5e7eb] transition-colors cursor-pointer m-5 shrink-0">
                <AppleIcon className="w-12 h-12" />
              </div>
            </div>

            <div className="w-full border-2 my-5" />
            <form onSubmit={handleRegister} className={`${isPC ? "" : "p-8"}`}>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Nom d'utilisateur
                </label>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Nom d'utilisateur"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="mb-4 relative">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Mot de passe
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    placeholder="Mot de passe"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
              <div className="mb-6 relative">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Confirmez le mot de passe
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    placeholder="Confirmez le mot de passe"
                    required
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                  >
                    {showPassword ? "Hide" : "Show"}
                  </button>
                </div>
              </div>
              <div className="mb-6 relative">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Age
                </label>
                <div className="relative">
                  <input
                    type="number"
                    value={age}
                    min={5}
                    max={99}
                    onChange={(e) => setAge(Number(e.target.value))}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    placeholder="Age"
                    required
                  />
                </div>
              </div>
              <div className="mb-6 relative">
                <label className="block mb-2 text-sm font-medium text-gray-700">
                  Gender
                </label>
                <div className="relative">
                  <Select
                    className="w-full"
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={gender}
                    label="Gender"
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Aucun</em>
                    </MenuItem>
                    <MenuItem value="female">Femme</MenuItem>
                    <MenuItem value="male">Homme</MenuItem>
                    <MenuItem value="non-binary">Non-binaire</MenuItem>
                  </Select>
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-primary text-white py-2 rounded-md hover:bg-primary-dark transition-colors"
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "S'inscrire"
                )}
              </button>
              {error && <p className="mt-4 text-red-500">{error}</p>}
            </form>
            <p className="mt-4 text-center">
              Deja inscrit?{" "}
              <a href="/login" className="text-blue-500 hover:underline">
                Se connecter
              </a>
            </p>
            {message && <p className="mt-4 text-green-500">{message}</p>}
          </div>
        </div>
      </div>
    </motion.div>
  );
};