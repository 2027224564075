import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Box, TextField, InputLabel, MenuItem, FormControl, Select, Typography } from "@mui/material";

interface TextEditorProps {
  data: {
    text: string;
    style?: {
      color?: string;
      fontSize?: string;
      width?: string;
      fontFamily?: string;
      textAlign?: string;
      lineHeight?: string;
      letterSpacing?: string;
      fontWeight?: string;
    };
  };
  onChange: (data: any) => void;
}

export const TextEditor: React.FC<TextEditorProps> = ({ data, onChange }) => {
  const defaultStyle = {
    color: "black",
    fontSize: "16px",
    width: "100%",
    fontFamily: "Arial",
    textAlign: "left",
    lineHeight: "1.5",
    letterSpacing: "normal",
    fontWeight: "normal"
  };

  const [text, setText] = useState(data.text);
  const [style, setStyle] = useState({ ...defaultStyle, ...data.style });

  useEffect(() => {
    setText(data.text);
  }, [data.text]);

  const handleEditorChange = (event: any, editor: any) => {
    const newText = editor.getData();
    setText(newText);
    onChange({
      text: newText,
      style
    });
  };

  const handleStyleChange = (styleProp: string, value: string) => {
    const updatedStyle = { ...style, [styleProp]: value };
    setStyle(updatedStyle);
    onChange({
      text,
      style: updatedStyle
    });
  };

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={text}  
        onChange={handleEditorChange}
      />
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Text Style</Typography>
        <TextField
          label="Color"
          type="color"
          value={style.color}
          onChange={(e) => handleStyleChange("color", e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Font Size"
          type="number"
          value={style.fontSize}
          onChange={(e) => handleStyleChange("fontSize", e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Font Family</InputLabel>
          <Select
            value={style.fontFamily}
            onChange={(e) => handleStyleChange("fontFamily", e.target.value)}
          >
            <MenuItem value="Arial">Arial</MenuItem>
            <MenuItem value="Georgia">Georgia</MenuItem>
            <MenuItem value="Times New Roman">Times New Roman</MenuItem>
            <MenuItem value="Verdana">Verdana</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Text Align</InputLabel>
          <Select
            value={style.textAlign}
            onChange={(e) => handleStyleChange("textAlign", e.target.value)}
          >
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="center">Center</MenuItem>
            <MenuItem value="right">Right</MenuItem>
            <MenuItem value="justify">Justify</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Line Height"
          type="text"
          value={style.lineHeight}
          onChange={(e) => handleStyleChange("lineHeight", e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Letter Spacing"
          type="text"
          value={style.letterSpacing}
          onChange={(e) => handleStyleChange("letterSpacing", e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Font Weight</InputLabel>
          <Select
            value={style.fontWeight}
            onChange={(e) => handleStyleChange("fontWeight", e.target.value)}
          >
            <MenuItem value="normal">Normal</MenuItem>
            <MenuItem value="bold">Bold</MenuItem>
            <MenuItem value="bolder">Bolder</MenuItem>
            <MenuItem value="lighter">Lighter</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Width"
          type="text"
          value={style.width}
          onChange={(e) => handleStyleChange("width", e.target.value)}
          fullWidth
        />
      </Box>
    </div>
  );
};
