import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { BestsellerData, fetchPublic, getProducts, ProductType } from "../../../utils";
import { ProductCard } from "./productCard";
import { Loading } from "../Loading";

export const BestSeller = () => {
  const [bestsellers, setBestsellers] = useState<ProductType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBestsellers = async () => {
      try {
        setLoading(true);
        const currentBestseller = await fetchPublic(`/public/info/bestseller`);
        const bestsellerData: BestsellerData | undefined = Object.values(currentBestseller.info)[0] as BestsellerData;
        if (bestsellerData) {
          const products = await getProducts();
          const bestsellerProducts = products.filter(
            (product) => product.id === bestsellerData.id
          );
          setBestsellers(bestsellerProducts);
        }
      } catch (error) {
        console.error("Error fetching bestsellers:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBestsellers();
  }, []);
  if(loading)return <Loading/>

  return (
    <Box position="relative" display="flex" justifyContent="center">
      {bestsellers.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </Box>
  );
};
