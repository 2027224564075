import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { CategoriesMenu, getUserRole, HeaderMenu, MenuIcon } from "../../utils";
import * as React from "react";
import { useAuth } from "../../utils/context/authContext";
import { useNavigate } from "react-router-dom";

export const DrawerMenu = () => {
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState<string | null>(null);
  const { user } = useAuth();
  const navigate = useNavigate()
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
 

  React.useEffect(() => {
    if (user) {
      (async () => {
        const userRole = await getUserRole(user.userID);
        setRole(userRole);
      })();
    }
  }, [user]);

  const DrawerList = (
    <Box
      sx={{ width: "80vw" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div
        onClick={toggleDrawer(false)}
        className="flex w-full items-center justify-end text-left text-5xl cursor-pointer"
      >
        &times;
      </div>
      <List>
        {HeaderMenu.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton component="a" href={item.path}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
         {role && (role === "admin" || role === "manager") && (
          <ListItem key="manager" disablePadding>
            <ListItemButton component="a"  href={'/manager'}>
              <ListItemText primary="Gestion" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        {CategoriesMenu.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton onClick={()=>navigate(`/products/${item.path}`)}>
              <ListItemIcon>
                {" "}
                {React.cloneElement(item.icon, {
                  className: "w-5 h-5 fill-current text-black",
                })}
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <MenuIcon
        onClick={toggleDrawer(true)}
        style={{
          width: "50px",
          height: "50px",
          fill: "white",
          cursor: "pointer",
        }}
      />

      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </div>
  );
};
