import React, { useState, useEffect } from "react";
import Slider, { Settings } from "react-slick";
import { CardContent, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";
import { apiSrcUrl, useWindowSize } from "utils";
import { getArticlesToShow } from "./product";

interface Article {
  title: string;
  link: string;
  pubDate: string;
  description: string;
  imageUrl: string;
}

interface RssFeedProps {
  search: string;
}

export const RssFeed: React.FC<RssFeedProps> = ({ search }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [isHovered, setIsHovered] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const { isMobile, width } = useWindowSize();
  const [loading, setLoading] = useState(true);

  // Function to update slidesToShow based on window width and article count
  const updateSlidesToShow = (width: number, articlesLength: number) => {
    const newSlidesToShow = getArticlesToShow(width, articlesLength);
    setSlidesToShow(newSlidesToShow > 3 ? 3 : newSlidesToShow);
  };

  useEffect(() => {
    // Fetching articles on component mount or when search changes
    const fetchNews = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${apiSrcUrl}/public/feed?q=${encodeURIComponent(search)}`
        );
        const data = await response.json();
        setArticles(data);
        // Update slidesToShow based on initial window width and article count
        updateSlidesToShow(window.innerWidth, data.length);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching news:", error);
        setLoading(false);
      }
    };
    fetchNews();
  }, [search]);

  useEffect(() => {
    // Update slidesToShow based on window resize and articles length
    const handleResize = () => {
      const newWidth = window.innerWidth;
      updateSlidesToShow(newWidth, articles.length);
    };

    window.addEventListener("resize", handleResize);

    // Also update slidesToShow when the articles length changes
    updateSlidesToShow(width, articles.length);

    return () => window.removeEventListener("resize", handleResize);
  }, [articles.length, width]); // Triggered on articles length and window size change

  const SampleNextArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <ArrowForwardIosIcon
        className={className}
        style={{
          ...style,
          display: "block",
          color: isHovered ? "#000" : "#ccc",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props: any) => {
    const { className, style, onClick } = props;
    return (
      <ArrowBackIosIcon
        className={className}
        style={{
          ...style,
          display: "block",
          color: isHovered ? "#000" : "#ccc",
        }}
        onClick={onClick}
      />
    );
  };

  const settings: Settings = {
    infinite: articles.length > 1,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: articles.length > 1,
    swipeToSlide: true,
    draggable: true,
    centerMode: !isMobile, // Disable center mode on mobile
    centerPadding: isMobile ? "0px" : "60px", // Remove center padding for mobile
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="p-4 w-full" // Ensure full width
    >
      <Slider {...settings}>
        {loading ? (
          // Show Circular Progress when loading is true
          <div className="flex w-full h-full justify-center items-center">
            <CircularProgress />
          </div>
        ) : articles.length > 0 ? (
          articles.map((article, index) => (
            <div
              className={`mx-4 p-8 ${
                isMobile
                  ? "h-[650px] w-full"
                  : `w-[200px] ${
                      slidesToShow === 2 ? "h-[700px]" : "h-[650px]"
                    }`
              } flex flex-col justify-between`}
              key={index}
            >
              <div className="border-2 rounded-bl flex flex-col h-full">
                <div className="w-full min-h-[200px] max-h-[200px] overflow-hidden">
                  <img
                    src={article.imageUrl}
                    alt={article.title}
                    className="h-full w-full object-cover"
                  />
                </div>

                <CardContent className="flex flex-col flex-grow">
                  <Typography variant="h6" component="div" gutterBottom>
                    {article.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="flex-grow"
                  >
                    {article.description.slice(0, 200)}...
                  </Typography>
                  <a
                    href={article.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline mt-2 block"
                  >
                    Read more
                  </a>
                </CardContent>
              </div>
            </div>
          ))
        ) : (
          // Show message when no articles are found and not loading
          <div className="flex w-full h-full justify-center items-center">
            <Typography variant="h6" color="text.secondary">
              Pas d'article trouvé
            </Typography>
          </div>
        )}
      </Slider>
    </div>
  );
};
