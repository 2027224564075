
import { breakpoints } from "../constant";
import { useState, useEffect } from "react";


export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: window.innerWidth <= breakpoints.mobile,
    isTablet: window.innerWidth > breakpoints.mobile && window.innerWidth <= breakpoints.tablet,
    isPC: window.innerWidth > breakpoints.tablet,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= breakpoints.mobile,
        isTablet: window.innerWidth > breakpoints.mobile && window.innerWidth <= breakpoints.tablet,
        isPC: window.innerWidth > breakpoints.tablet,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};


