import React from "react";
import { ContentPart } from "./ContentPart";

interface DisplayContentProps {
  table: string;
  contentParts: any[];
}

export const DisplayContent: React.FC<DisplayContentProps> = ({ table, contentParts }) => {
  return (
    <div className="w-full ">
     
      {contentParts.map((part) => (
        <div key={part.id} style={{ marginBottom: "20px" }}>
          <ContentPart part={part} />
        </div>
      ))}
    </div>
  );
};