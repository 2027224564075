import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../context";
import { PrivateRouteProps } from "../types";
import { Loading } from "../../components";
import { checkUserAccess } from "utils/api";
import { getToken } from "utils/helper";

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  roles,
  ...rest
}) => {
  const { login } = useAuth();
  const location = useLocation();
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const token = getToken();
  const hasChecked = useRef(false);

  useEffect(() => {
    const performSecurityCheck = async () => {
      if (!token) {
        setLoading(false);
        return;
      }

      if (hasChecked.current) {
        return;
      }

      hasChecked.current = true;
      try {
        const { data, redirect } = await checkUserAccess(
          location.pathname,
          token
        );
        if (redirect) {
          window.location.replace(redirect);
          return;
        }
        if (data.user) {
          await login(data.userData);
        }

        setAuthorized(true);
        
      } catch (error) {
        setAuthorized(false);
      } finally {
        setLoading(false);
      }
    };

    performSecurityCheck();
  }, [location.pathname, token, login]);

  if (loading) {
    return <Loading />;
  }

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!authorized) {
    return <Navigate to="/not-authorized" state={{ from: location }} replace />;
  }

  return <Component {...rest} />;
};
