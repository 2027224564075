import { ProductType } from "utils/types";
import { apiSrcUrl } from "../ApiRoutes";

export const fetchPublic = async (routes:string) => {
    try {
      const response = await fetch(`${apiSrcUrl}${routes}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch images');
      }
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch images', error);
      return { info: [] };
    }
  };


  export const getProducts = async (): Promise<ProductType[]> => {
    const result = await fetchPublic(`/public/product`);
    if (result.products) {
      const productsArray = Object.values(result.products) as ProductType[];
      return productsArray;
    }
    return [];
  };
  
