import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  Divider,
  TextField,
  Button,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useAuth } from "../../utils/context/authContext";
import { apiSrcUrl, auth, getUserByID, updateUser } from "../../utils";
import { User } from "../../utils/types";
import { CustomSnackbar } from "../../components";
import { useNavigate } from "react-router-dom";

export const SettingsSection: React.FC = () => {
  const { user: authUser } = useAuth();
  const [formUser, setFormUser] = useState<User | null>(null);
  const [username, setUsername] = useState("");
  const [age, setAge] = useState<number | string>("");
  const [gender, setGender] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmationInput, setConfirmationInput] = useState("");
  const initialFetch = useRef(true);
  const [, setConfirmationCode] = useState<string | null>(null);
  const router = useNavigate();
  useEffect(() => {
    const fetchUserData = async () => {
      if (authUser && initialFetch.current) {
        const fetchedUser = await getUserByID(authUser.userID);
        if (fetchedUser) {
          setFormUser(fetchedUser);
          setUsername(fetchedUser.username);
          setAge(fetchedUser.age);
          setGender(fetchedUser.gender);
        }
        setLoading(false);
        initialFetch.current = false;
      }
    };
    fetchUserData();
  }, [authUser]);

  const handleUpdate = async () => {
    if (!authUser || !formUser) return;

    const updatedUser = { ...formUser, username, age: Number(age), gender };
    await updateUser(formUser.userID, updatedUser);
    setFormUser(updatedUser);
    setSnackbarMessage("Profil mis à jour avec succès!");
    setSnackbarSuccess(true);
    setSnackbarOpen(true);
    setError(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setConfirmationInput("");
  };

  const handleDeleteAccount = async () => {
    setLoading(true);
    setError(null);
    setConfirmationCode(null);

    try {
      if (auth.currentUser) {
        console.log("starting to delete ... ");
        const idToken = await auth.currentUser.getIdToken();
        const res = await fetch(`${apiSrcUrl}/private/delete-account`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            signed_request: `${auth.currentUser.uid}`, // The user's ID
          }),
        });

        const result = await res.json();

        if (res.ok) {
          setConfirmationCode(result.confirmation_code);
          setSnackbarMessage("Votre compte a été supprimé avec succès.");
          setSnackbarSuccess(true);
          setSnackbarOpen(true);
          router("/"); // Redirect after successful deletion
        } else {
          throw new Error(result.error || "Échec de la suppression du compte");
        }
      } else {
        throw new Error("Utilisateur non authentifié");
      }
    } catch (error: any) {
      setError(error.message || "Quelque chose s'est mal passé");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = () => {
    if (confirmationInput === "CONFIRMER") {
      handleDeleteAccount(); // Trigger DeleteAccount's logic
      handleCloseDialog();
    } else {
      setError("Vous devez taper CONFIRMER pour supprimer votre compte.");
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h5">Paramètres</Typography>
      <Divider sx={{ my: 2 }} />
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nom d'utilisateur"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Âge"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              fullWidth
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Genre</InputLabel>
              <Select
                value={gender}
                onChange={(e) => setGender(e.target.value as string)}
              >
                <MenuItem value="female">Femme</MenuItem>
                <MenuItem value="male">Homme</MenuItem>
                <MenuItem value="non-binary">Non-binaire</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdate}
          sx={{ mt: 2 }}
        >
          Mettre à jour
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDeleteClick}
          sx={{ mt: 2 }}
        >
          Supprimer mon compte
        </Button>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />

      {/* Dialog for confirmation */}
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirmez la suppression de votre compte</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Pour supprimer votre compte, veuillez taper <b>CONFIRMER</b> dans le
            champ ci-dessous.
          </DialogContentText>
          <DialogContentText color="error">
            Attention : En supprimant votre compte, vous risquez de perdre
            toutes vos données, y compris vos BSB Coins. Assurez-vous de les
            dépenser avant de supprimer votre compte.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            label="CONFIRMER"
            fullWidth
            value={confirmationInput}
            onChange={(e) => setConfirmationInput(e.target.value)}
          />
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
