import { signInWithEmailAndPassword } from "firebase/auth";
import { apiSrcUrl } from "../ApiRoutes";
import { User } from "utils/types";
import { auth } from "utils/firebase";

interface FetchLoginProps {
  email: string;
  password: string;
}

export const fetchLogin = async ({ email, password }: FetchLoginProps) => {
  const apiRouteUrl = `${apiSrcUrl}/auth/signin`;

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    if(user){
      const response = await fetch(apiRouteUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
  
      const data = await response.json();
      console.log("fetch login :" + data.user);
  
      if (response.ok) {
        
        return { success: true, token: data.token, user: data.user as User };
      } else {
        return { success: false, message: data.error };
      }
    }

  } catch (error) {
    return {
      success: false,
      message: "An error occurred. Please try again later.",
    };
  }
};

export const validateToken = async (token: string) => {
  try {
    const apiRouteUrl = `${apiSrcUrl}/auth/validate`;
    const response = await fetch(apiRouteUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token }),
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, user: data.userPayload as User };
    } else {
      return { success: false, message: data.message };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred. Please try again later.",
    };
  }
};


export const retrieveToken = async (token: string, userID: string) => {
  try {
    const apiRouteUrl = `${apiSrcUrl}/auth/token/${userID}`;
    const response = await fetch(apiRouteUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
      },
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, response: data };
    } else {
      return { success: false, message: data.error || "Failed to retrieve token" };
    }
  } catch (error: any) {
    return {
      success: false,
      message: error.message || "An error occurred. Please try again later.",
    };
  }
};


export const fetchGoogleSignIn = async (token: string,result:any) => {
  try {
    const response = await fetch(`${apiSrcUrl}/auth/google-signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token,result }),
    });

    const data = await response.json();

    if (response.ok) {
      console.log("google Login : ", data.user);
      return { success: true, user: data.user, token: data.token };
    } else {
      return { success: false, message: data.error };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred. Please try again later.",
    };
  }
};



export const fetchFacebookSignIn = async (token: string,result:any) => {
  try {
    const response = await fetch(`${apiSrcUrl}/auth/facebook-signin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token,result }),
    });

    const data = await response.json();

    if (response.ok) {
      return { success: true, user: data.user, token: data.token };
    } else {
      return { success: false, message: data.error };
    }
  } catch (error) {
    return {
      success: false,
      message: "An error occurred. Please try again later.",
    };
  }
};


export const fetchSignUp = async (
  email: string,
  password: string,
  username: string,
  age: number,
  gender: string
): Promise<{
  success: boolean;
  message?: string;
  token?: string;
  user?: User;
}> => {
  try {
    const apiRouteUrl = `${apiSrcUrl}/auth/signup`;
    const response = await fetch(apiRouteUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, username, age, gender }),
    });
    const data = await response.json();

    if (response.ok) {
      return { success: true, token: data.token, user: data.user };
    } else {
      return { success: false, message: data.error };
    }
  } catch (error: any) {
    return {
      success: false,
      message: "Une erreur est survenue. Veuillez réessayer plus tard.",
    };
  }
};

export const checkUserAccess = async (route: string, token: string) => {
  try {
    const encodedPath = encodeURIComponent(route);
    const response = await fetch(`${apiSrcUrl}/auth/security/${encodedPath}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    const data = await response.json();

    if (!response.ok) {
      if (data.redirect) {
        return { redirect: data.redirect };
      }
      throw new Error(data.error || 'Unknown error');
    }

    return { data };
  } catch (error) {
    console.error('Error checking user access:', error);
    throw error;
  }
};
