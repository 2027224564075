import { useEffect, useState } from "react";
import { ContentPartType, fetchPageContent, useWindowSize } from "utils";
import { useUtility } from "utils/context/UtilityContext";
import { Header } from "./Header";
import { DisplayContent } from "components/manager";
import { Footer } from "./footer";
import { Loading } from "./Loading";

interface PageProps {
  name: string;
}

export const FetchAPage: React.FC<PageProps> = ({ name }) => {
  const { marginBottomHeader } = useUtility();
  const [page, setPage] = useState<ContentPartType[]>([]);
  const [loading, setLoading] = useState(true);
  const { isPC } = useWindowSize();
  useEffect(() => {
    setLoading(true);
    const fetchPage = async () => {
      const result = await fetchPageContent(`/${name}`);
      if (result.success) {
        const { id, ...rest } = result.data;
        setPage(Object.values(rest));
      }
      setLoading(false);
    };
    fetchPage();
  }, [name]);

  if (loading) return <Loading />
  return (
    <div>
      <div className={`${marginBottomHeader} ${isPC ? "px-32 p-16" : "p-8"}`}>
        <Header />
        {page && <DisplayContent table={name} contentParts={page} />}
      </div>
      <Footer />
    </div>
  );
};
