import React from "react";
import { ImagesList, TextDisplay, VideoDisplay } from "./textFunctions";
import { TextImages } from "components/shared";

interface ContentPartType {
  id?: string;
  type: string;
  data: any;
}

interface ContentPartProps {
  part: ContentPartType;
}

export const ContentPart: React.FC<ContentPartProps> = ({ part }) => {
  switch (part.type) {
    case "text":
      return (
        <TextDisplay
          text={part.data.text}
          color={part.data.style?.color || "black"}
          fontSize={part.data.style?.fontSize || "16px"}
          fontFamily={part.data.style?.fontFamily || "Arial"}
          width={part.data.style?.width || "100%"}
          textAlign={part.data.style?.textAlign || "left"}
          lineHeight={part.data.style?.lineHeight || "1.5"}
          letterSpacing={part.data.style?.letterSpacing || "normal"}
          fontWeight={part.data.style?.fontWeight || "normal"}
        />
      );
    case "image":
      return <ImagesList itemData={part.data.images} width={'80%'} />;

    case "textWithImageLeft":
      return <TextImages parts={part} side="left" />;

    case "textWithImageRight":
      return <TextImages parts={part} side="right" />;

    case "video":
      return <VideoDisplay videoLink={part.data} />;
    case "link":
      return (
        <a href={part.data} target="_blank" rel="noopener noreferrer">
          {part.data}
        </a>
      );
    default:
      return null;
  }
};
