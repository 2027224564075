import { Footer, Header } from 'components/shared';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Or useNextRouter for Next.js
import { apiSrcUrl, getToken } from 'utils';
import { useUtility } from 'utils/context/UtilityContext';

export const ResendVerificationEmail = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useNavigate(); // Or useRouter for Next.js
  const { marginBottomHeader } = useUtility();

  const handleResendVerificationEmail = async () => {
    setLoading(true);
    setMessage('');

    try {
      const token = getToken(); // Get the token from your authentication context or local storage
    
      const response = await fetch(`${apiSrcUrl}/auth/confirmEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ token }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage(`Verification email sent.`);
      } else if (data.redirectToSignUp) {
        // If the user does not exist, redirect to the signup page
        history('/signup'); // Or use history.replace('/signup') to replace the current entry
      } else {
        setMessage(`Failed to send verification email: ${data.error}`);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred while sending the verification email.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen justify-between">
      <Header/>
      <div className={` ${marginBottomHeader} flex-grow flex flex-col justify-center items-center p-4 md:p-16 `}>
        <div className="max-w-md w-full space-y-8">
          <h2 className="text-center text-3xl font-extrabold text-gray-900">
            Resend Verification Email
          </h2>
         
          <button
            onClick={handleResendVerificationEmail}
            disabled={loading}
            className={`w-full py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
              loading 
                ? "bg-primary cursor-not-allowed"
                : "bg-primary hover:bg-indigo-700"
            } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            {loading ? "Sending..." : "Resend Verification Email"}
          </button>
          {message && (
            <p className="text-center mt-4 text-sm text-gray-600">{message}</p>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};
