import React from 'react';
import { Box, Typography } from '@mui/material';

interface GiftCardStatisticsProps {
  stats: {
    totalGiftCards: number;
    totalSold: number;
    totalValue: number;
    totalRevenue: number;
    totalPotentialRevenue: number;
  };
}

const GiftCardStatistics: React.FC<GiftCardStatisticsProps> = ({ stats }) => {
  return (
    <Box mt={4}>
      <Typography variant="h6">Statistics</Typography>
      <Typography>Total Gift Cards: {stats.totalGiftCards}</Typography>
      <Typography>Total Sold: {stats.totalSold}</Typography>
      <Typography>Total Value spent: {stats.totalValue} $</Typography>
      <Typography>Total Revenue: {stats.totalRevenue} $</Typography>
      <Typography>
        Total Potential Revenue: {stats.totalPotentialRevenue} $
      </Typography>
      <Typography>
        Total Potential Revenue Bonus: {stats.totalPotentialRevenue - stats.totalValue} $
      </Typography>
    </Box>
  );
};

export default GiftCardStatistics;
