import { getToken } from "utils/helper";
import { PromoCode } from "utils/types";
import { apiSrcUrl } from "../ApiRoutes";


const apiUrl = `${apiSrcUrl}/private/promo-codes`;

export const fetchPromoCodes = async (): Promise<{ [key: string]: PromoCode }> => {
    const token = getToken();
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
  
    if (!response.ok) {
      throw new Error('Failed to fetch promo codes');
    }
  
    return response.json();
  };

export const createPromoCode = async (promoCode: PromoCode): Promise<PromoCode> => {
  const token = getToken();
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(promoCode),
  });

  if (!response.ok) {
    throw new Error('Failed to create promo code');
  }

  return response.json();
};

export const updatePromoCode = async (id: string, promoCode: PromoCode): Promise<PromoCode> => {
  const token = getToken();
  const response = await fetch(`${apiUrl}/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(promoCode),
  });

  if (!response.ok) {
    throw new Error('Failed to update promo code');
  }

  return response.json();
};

export const deletePromoCode = async (id: string): Promise<void> => {
  const token = getToken();
  const response = await fetch(`${apiUrl}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to delete promo code');
  }
};
