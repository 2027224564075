import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { fetchUploadImage, fetchImagesFromServer, getToken } from "utils";

interface ImageSelectorProps {
  table:string;
  len:number
  type:string;
  initialUrls?: string[];
  onFileChange: (files: File[] | null, previewUrls: string[] | null) => void;
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({table, len,type,initialUrls = [], onFileChange }) => {
  const [previewUrls, setPreviewUrls] = useState<string[]>(initialUrls);
  const [, setFiles] = useState<File[]>([]);
  const [imageList, setImageList] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const token = getToken();

  useEffect(() => {
    const fetchImages = async () => {
      if (token) {
        const response = await fetchImagesFromServer(token);
        if (response.success) {
          const images = response.images;
          const urls = [];
          for (const category in images) {
            for (const imageName in images[category]) {
              urls.push(images[category][imageName].url);
            }
          }
          setImageList(urls);
        } else {
          console.error("Failed to fetch images:", response.message);
        }
      }
    };

    fetchImages();
  }, [token]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      const urlPromises = selectedFiles.map(async (file) => {
        if (token) {
          const response = await fetchUploadImage(
            file,
          `images/pages/${table}/${file.name}_${table}_${type}_${len+1}`,
          token,
          "/private/images",
          `imagesUrl/pages/${table}/${file.name}_${table}_${type}_${len+1}`
          );
          return response.url;
        }
        return "";
      });
      const newUrls = await Promise.all(urlPromises);
      setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
      setPreviewUrls((prevUrls) => [...prevUrls, ...newUrls]);
      onFileChange(selectedFiles, newUrls);
      setImageList((prevList) => [...prevList, ...newUrls]);
    }
  };

  const handleSelectImage = (url: string) => {
    setPreviewUrls((prevUrls) => [...prevUrls, url]);
    onFileChange(null, [...previewUrls, url]);
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Select Images
      </Button>
      <Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>Select Images</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {imageList.map((url, index) => (
              <Grid item xs={4} key={index} onClick={() => handleSelectImage(url)}>
                <img src={url} alt={`Preview ${index}`} style={{ width: "100%", cursor: "pointer" }} />
              </Grid>
            ))}
            <Grid item xs={4}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="contained-button-file"
                type="file"
                onChange={handleFileChange}
                multiple
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  Upload New Images
                </Button>
              </label>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      {previewUrls.map((url, index) => (
        <img key={index} src={url} alt={`Preview ${index}`} style={{ width: "100%", marginTop: "10px" }} />
      ))}
    </Box>
  );
};
