import { getYoutubeEmbedUrl, useWindowSize } from "utils";



interface VideoDisplayProps {
  videoLink: string;
}

export const VideoDisplay: React.FC<VideoDisplayProps> = ({ videoLink }) => {
  const { isMobile } = useWindowSize();
  const videoUrl = getYoutubeEmbedUrl(videoLink);
  return (
    <div className={`flex ${isMobile ? '' : 'p-16'} justify-center items-center`}>
      <iframe
        src={videoUrl}
        title="Video Content"
        style={{ width: isMobile ? "90%" : "80%", height: isMobile ? "100%" : "500px", borderRadius: "8px" }}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};
