import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NotAuthorizedProps {
    textInfo: string;
  }
  

export const NotAuthorized: React.FC<NotAuthorizedProps> = ({textInfo}) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="text-center">
        <div className="text-4xl mb-4">
          <span role="img" aria-label="lock">
            🔒
          </span>
        </div>
        <h1 className="text-2xl font-bold">Vous n'avez pas accès à cette page.</h1>
        <p className="mb-4">{textInfo}</p>
        <button
          onClick={handleGoBack}
          className="bg-primary text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors"
        >
         Aller à la page précédente
        </button>
      </div>
    </div>
  );
};


