import { Footer, GotoLogin, Header, Loading } from "../../shared";
import { rolesAdmin, useDecodeToken, useSecurityCheck } from "../../../utils";
import { motion } from "framer-motion";
import { useEffect, useState, useCallback } from "react";
import { useAuth } from "../../../utils/context/authContext";
import { useUtility } from "../../../utils/context/UtilityContext";
import { ProductTable } from "./productTable";

export const ProductManagement = () => {
  const { userFromToken, token } = useDecodeToken();
  const [Logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  const { login } = useAuth();
  const { marginBottomHeader } = useUtility();
  const securityCheck = useSecurityCheck(rolesAdmin);

    const handleLogin = useCallback(async () => {
    if (token && userFromToken) {
      setLogged(true);
      await login(userFromToken);
    }
    setLoading(false);
  }, [token, userFromToken, login]);

  useEffect(() => {
    handleLogin();
  }, [handleLogin]);

  if (loading) {
    return <Loading />;
  }

  if (!Logged) {
    return <GotoLogin />;
  }
  if (securityCheck) {
    return securityCheck;
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4`}
    >
      <Header />
      <ProductTable />
      <Footer />
    </motion.div>
  );
};
