import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { getUserByID, getUserDetails, updateUser, User } from "../../../utils";
import { CustomSnackbar } from "../../shared";

interface UserDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  initialData?: User | null;
}

const initialUserState: UserWithPromoCode = {
  userID: "",
  username: "",
  email: "",
  avatarUrl: "",
  age: 0,
  gender: "",
  role: "visitor",
  amount: 0,
  isConfirmed: false,
  promoCodeUsageCount: 0, // Initialize promo code usage count
};

interface UserWithPromoCode extends User {
  promoCodeUsageCount: number;
}

export const UserDialog: React.FC<UserDialogProps> = ({
  open,
  onClose,
  onSave,
  initialData,
}) => {
  const [user, setUser] = useState<UserWithPromoCode>(initialUserState);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

  useEffect(() => {
    if (initialData) {
      fetchUserWithPromoCodeUsage(initialData.userID);
    } else {
      setUser(initialUserState);
    }
  }, [initialData]);

  const fetchUserWithPromoCodeUsage = async (userID: string) => {
    const userData = await getUserByID(userID);
    const promoCodeUsageCount = await getUserDetails(userID, "promo");
    if(userData)
    setUser({ ...userData, promoCodeUsageCount });
  };



  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setUser({ ...user, [name]: checked });
  };

  const handleSaveUser = async () => {
    setLoading(true);
    try {
      if (user.userID) {
        await updateUser(user.userID, user);
      } else {
        //await addUser(user);
      }
      onSave();
      onClose();
      setSnackbarMessage("Utilisateur mis à jour avec succès!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Erreur lors de la mise à jour de l'utilisateur");
      setSnackbarSuccess(false);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {user.userID ? "Modifier l'utilisateur" : "Ajouter un utilisateur"}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label="Nom d'utilisateur"
              name="username"
              value={user.username}
              onChange={handleChange}
            />
            <TextField
              label="Email"
              name="email"
              value={user.email}
              onChange={handleChange}
            />
            <TextField
              label="URL de l'avatar"
              name="avatarUrl"
              value={user.avatarUrl}
              onChange={handleChange}
            />
            <TextField
              label="Âge"
              name="age"
              type="number"
              value={user.age}
              onChange={handleChange}
            />
            <TextField
              label="Genre"
              name="gender"
              value={user.gender}
              onChange={handleChange}
            />

            <TextField
              label="Utilisation de code promo"
              name="promoCodeUsageCount"
              type="number"
              value={user.promoCodeUsageCount}
              onChange={handleChange}
              disabled
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={user.isConfirmed}
                  onChange={handleCheckboxChange}
                  name="isConfirmed"
                />
              }
              label="Confirmé"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Annuler</Button>
          <Button onClick={handleSaveUser} color="primary" disabled={loading}>
            {loading ? (
              <CircularProgress size={24} />
            ) : user.userID ? (
              "Mettre à jour"
            ) : (
              "Ajouter"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </>
  );
};
