import React from "react";
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { ProductType, SetFlag, useWindowSize } from "../../../utils";

const PriceButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: "#254c78",
  color: "white",
  "&:hover": {
    backgroundColor: "#203b5b",
  },
}));

const BestsellerTag = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: "#f5d000",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontSize: "0.75rem",
  fontWeight: "bold",
}));
interface ProductCardProps {
  product: ProductType;
}
export const ProductCard: React.FC<ProductCardProps> = (product) => {
  const { isPC } = useWindowSize();
  return (
    <div className="flex items-center justify-center w-full">
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: isPC ? "47vw" : "87vw",
            height: 300,
          },
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            display: "flex",
            position: "relative",
            backgroundColor: "#e8edf2",
            padding: "2px",
          }}
        >
          <BestsellerTag>Bestseller</BestsellerTag>
          <div
            style={{
              width: isPC ? "50%" : "45%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: isPC ? "16px" : "2px",
            }}
          >
            <CardMedia
              component="img"
              height={isPC ? "140" : "250"}
              image={product.product.image}
              alt="PlayStation Store"
              sx={{ width: isPC ? "80%" : "100%", borderRadius: "16px" }}
            />
          </div>
          <CardContent
            sx={{
              width: isPC ? "50%" : "55%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              padding: "16px",
              paddingBottom: "32px",
            }}
          >
            <div className={`w-full flex items-center justify-center`}>
              <Typography
                variant="h6"
                component="div"
                display={"flex"}
                sx={{ width: "80%", gap: "5%", textAlign: "center" }}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {product.product.name} {product.product.price}{" "}
                {product.product.currency}{" "}
                <SetFlag
                  countryCode={product.product.countryCode}
                  countryName={product.product.country}
                />
              </Typography>
            </div>

            <PriceButton variant="contained">
              {" "}
              {product.product.priceUSD} BSB Coin
            </PriceButton>
          </CardContent>
        </Paper>
      </Box>
    </div>
  );
};
