import { Box, Typography } from "@mui/material";
import { Footer, Header } from "components/shared";
import { useWindowSize } from "utils";
import { useUtility } from "utils/context/UtilityContext";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

export const Contact = () => {
  const { marginBottomHeader } = useUtility();
  const { isPC } = useWindowSize();

  return (
    <div>
      <Header />
      <div
        className={`${marginBottomHeader} ${
          isPC ? "px-32 p-16" : "p-8"
        } w-full bg-gray`}
      >
        <Typography
          variant={isPC ? "h2" : "h5"}
          component="div"
          gutterBottom
          sx={{
            fontSize: isPC ? "2.5rem" : "1.5rem",
            fontWeight: "bold",
          }}
        >
          Contactez-nous
        </Typography>
        <Typography
          variant={isPC ? "h6" : "body1"}
          component="div"
          gutterBottom
          sx={{
            fontSize: isPC ? "1.25rem" : "1rem",
          }}
        >
          Vous souhaitez nous contacter ? Nous serions ravis de vous entendre.
          Voici comment nous joindre.
        </Typography>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: isPC ? "row" : "column",
          padding: isPC ? "5%" : "10%",
          gap: isPC ? "5%" : "20px",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          border: "solid 2px",
          borderColor: "grey.300",
          borderRadius: "8px",
          backgroundColor: "white",
        }}
      >
        <ContactBox
          icon={<EmailIcon fontSize="large" />}
          title="Envoyez-nous un mail au"
          link="mailto:bsb243.lshi@gmail.com"
          linkText="bsb243.lshi@gmail.com"
        />
        <ContactBox
          icon={<WhatsAppIcon fontSize="large" />}
          title="Envoyez-nous un message au"
          link="https://wa.me/+48510347644"
          linkText="+48 510 347 644"
        />
        <ContactBox
          icon={<InstagramIcon fontSize="large" />}
          title="Suivez notre page Instagram"
          link="https://www.instagram.com/bsb.243/"
          linkText="@bsb.243"
        />
        <ContactBox
          icon={<FacebookIcon fontSize="large" />}
          title="Suivez notre page Facebook"
          link="https://www.facebook.com/profile.php?id=100070906878421"
          linkText="BSB"
        />
      </Box>
      <Footer />
    </div>
  );
};

interface ContactBoxProps {
  icon: React.ReactNode;
  title: string;
  link: string;
  linkText: string;
}

const ContactBox: React.FC<ContactBoxProps> = ({
  icon,
  title,
  link,
  linkText,
}) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      gap: "15px",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#8CA5BD",
      borderRadius: "8px",
      padding: "20px",
      width: "100%",
      maxWidth: "250px",
      height: "250px",
      color: "white",
      textAlign: "center",
    }}
  >
    {icon}
    <Typography variant="body1" component="div">
      {title}
    </Typography>
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "white", textDecoration: "none" }}
    >
      {linkText}
    </a>
  </Box>
);
