import  { useCallback, useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import {  fetchPublic, StepType, useWindowSize } from "../../utils";
import { Loading } from "./Loading";
import { Collapse, IconButton, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0 },
};

export const OrderSteps = () => {
  const { isMobile } = useWindowSize();
  const [steps, setSteps] = useState<StepType[]>([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState<number[]>([]);
  const controls = useAnimation();
  const { ref: stepsRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const fetchSteps = useCallback(async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/info/steps`);
    if (result.info) {
      setSteps(Object.values(result.info));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchSteps();
  }, [fetchSteps]);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const handleExpandClick = (index: number) => {
    if (expanded.includes(index)) {
      setExpanded(expanded.filter((i) => i !== index));
    } else {
      setExpanded([...expanded, index]);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <motion.div
      ref={stepsRef}
      className="flex w-full items-start justify-between flex-col"
      variants={containerVariants}
      initial="hidden"
      animate={controls}
    >
      {steps.map((step, index) => (
        <motion.div
          key={index}
          className={`flex gap-4 items-center justify-start ${isMobile?"p-4":"p-8"}  flex-row w-full`}
          variants={itemVariants}
        >
          <div className={`${isMobile?"w-16":"w-16"} flex gap-4 items-center justify-center`}>
            <div className={`rounded-full ${isMobile?"w-10 h-10":"w-12 h-12"}  flex text-center items-center justify-center bg-primary text-white text-2xl`}>
              {index + 1}
            </div>
          </div>

          <div className={`flex flex-col ${isMobile?"w-4/5":"w-95/100"} items-center justify-center`}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                cursor: "pointer",
              }}
              onClick={() => handleExpandClick(index)}
            >
              <Typography variant={isMobile?"body1":"h6"}>{step.step}</Typography>
              <IconButton
                aria-expanded={expanded.includes(index)}
                aria-label="show more"
              >
                {expanded.includes(index) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </Box>
            <Collapse
              in={expanded.includes(index)}
              timeout="auto"
              unmountOnExit
              sx={{
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  padding: 2,
                  whiteSpace: "normal",
                  alignItems: "flex-start",
                  textAlign: "left",
                }}
              >
                {step.description}
              </Typography>
            </Collapse>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};
