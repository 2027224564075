import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";
import { MessageDialogProps } from "utils";




export const MessageDialog: React.FC<MessageDialogProps> = ({ open, handleClose, message, item, user, type }) => {


let whatsappMessage =""
if(type==='STOCK'){
     whatsappMessage = item
    ? `Bonjour, je souhaite commander ${item.quantity} ${item.name} ${item.currency} ${item.country}, mais le stock est épuisé. Pouvez-vous m'aider? ${user.username}`
    : "Bonjour, je souhaite commander un produit, mais il y a un problème avec la commande.";

}
else if(type==='SOLDE'){
    whatsappMessage = item
    ? `Bonjour, je souhaite commander ${item.quantity} ${item.name} ${item.currency} ${item.country}, mais mon solde est insuffisant. Pouvez-vous m'aider a recharger mon compte? ${user.username}`
    : "Bonjour, je souhaite commander un produit, mais mon solde est insuffisant. Pouvez-vous m'aider a recharger mon compte?";
}

  // WhatsApp link with pre-filled message
  const whatsappLink = `https://wa.me/48510347644?text=${encodeURIComponent(whatsappMessage)}`;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Contacter le service client</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
        {item ? (
          <Typography>
            Produit: {item.name} <br />
            Prix: {item.price} BSB Coins <br />
            Quantité: {item.quantity} <br />
            Utilisateur: {user.username}
          </Typography>
        ) : (
          <Typography>Les détails du produit ne sont pas disponibles.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fermer
        </Button>
        <Button
          sx={{backgroundColor:'#128c7e', color:'white'}}
          onClick={() => window.open(whatsappLink, "_blank")}
        >
          Contacter via WhatsApp
        </Button>
      </DialogActions>
    </Dialog>
  );
};


