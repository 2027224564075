import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  DragIndicator as DragIndicatorIcon,
} from "@mui/icons-material";
import { motion } from "framer-motion";
import { useUtility } from "utils/context/UtilityContext";
import { CustomSnackbar, Header } from "components/shared";
import { ContentDialog } from "./ContentDialog";
import { ContentPartPreview } from "./ContentPartPreview";
import {
  fetchPageContent,
  postPageContent,
  putPageContent,
  deletePageContent,
} from "utils/api";
import {
  DndProvider,
  useDrop,
  useDrag,
  DropTargetMonitor,
  XYCoord,
} from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ItemTypes } from "utils";

interface ContentPartType {
  id?: string;
  type: string;
  data: any;
}

interface DraggableContentPartProps {
  part: ContentPartType;
  index: number;
  onEdit: (part: ContentPartType) => void;
  onDelete: (id: string) => void;
  movePart: (dragIndex: number, hoverIndex: number) => void;
}

const DraggableContentPart: React.FC<DraggableContentPartProps> = ({
  part,
  index,
  onEdit,
  onDelete,
  movePart,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CONTENT_PART,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CONTENT_PART,
    hover: (item: { index: number }, monitor: DropTargetMonitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      movePart(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={ref} style={{ opacity }} className="draggable-content-part">
      <ListItem>
        <IconButton>
          <DragIndicatorIcon />
        </IconButton>
        <ListItemText primary={part.type} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => onEdit(part)}>
            <EditIcon />
          </IconButton>
          <IconButton edge="end" onClick={() => onDelete(part.id!)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ContentPartPreview part={part} />
    </div>
  );
};

interface RichTextEditorProps {
  table: string;
  contentParts: ContentPartType[];
  setContentParts: React.Dispatch<React.SetStateAction<ContentPartType[]>>;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  table,
  contentParts,
  setContentParts,
}) => {
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPart, setCurrentPart] = useState<ContentPartType | null>(null);
  const { marginBottomHeader } = useUtility();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

  const fetchContentParts = useCallback(async () => {
    setLoading(true);
    try {
      const result = await fetchPageContent(`/${table}`);
      if (result.success) {
        const partsList = Object.keys(result.data).map((key) => ({
          id: key,
          ...result.data[key],
        }));
        setContentParts(partsList);
      } else {
        setContentParts([]);
      }
    } catch (error) {
      console.error("Error fetching content parts:", error);
      setContentParts([]);
    } finally {
      setLoading(false);
    }
  }, [table, setContentParts]);

  useEffect(() => {
    fetchContentParts();
  }, [table, fetchContentParts]);

  const handleSavePart = async (part: ContentPartType) => {
    setLoading(true);
    try {
      console.log("content part :", part.data);
      if (part.id) {
        await putPageContent(table, part);
        setContentParts((prevParts) =>
          prevParts.map((p) => (p.id === part.id ? part : p))
        );
      } else {
        const response = await postPageContent(table, part);
        setContentParts((prevParts) => [
          ...prevParts,
          { ...part, id: response.id },
        ]);
      }
      setSnackbarMessage("Content part saved successfully!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Error saving content part");
      setSnackbarSuccess(false);
    } finally {
      setOpenDialog(false);
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleDeletePart = async (id: string) => {
    setLoading(true);
    try {
      await deletePageContent(table, id);
      setContentParts((prevParts) =>
        prevParts.filter((part) => part.id !== id)
      );
      setSnackbarMessage("Content part deleted successfully!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Error deleting content part");
      setSnackbarSuccess(false);
    } finally {
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const movePart = async (dragIndex: number, hoverIndex: number) => {
    const reorderedParts = [...contentParts];
    const [movedPart] = reorderedParts.splice(dragIndex, 1);
    reorderedParts.splice(hoverIndex, 0, movedPart);

    setContentParts(reorderedParts);

    try {
      // Save reordered parts to the database
      for (const part of reorderedParts) {
        await putPageContent(table, part);
      }
      setSnackbarMessage("Content parts reordered successfully!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Error saving reordered content parts");
      setSnackbarSuccess(false);
    } finally {
      setSnackbarOpen(true);
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={` ${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll w-full border-4`}
      >
        <Box sx={{ width: "100%", paddingLeft: "10%", paddingRight: "10%" }}>
          <Header />
          <Typography variant="h4" gutterBottom marginTop={"10%"}>
            Content Parts List
          </Typography>
          <Button
            sx={{ marginLeft: "5px" }}
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Add New Content Part
          </Button>
          <div className="w-full">
            <List>
              {contentParts.map((part, index) => (
                <DraggableContentPart
                  key={part.id}
                  part={part}
                  index={index}
                  onEdit={(part: ContentPartType) => {
                    setCurrentPart(part);
                    setOpenDialog(true);
                  }}
                  onDelete={(id: string) => handleDeletePart(id)}
                  movePart={movePart}
                />
              ))}
            </List>
          </div>

          <ContentDialog
            table={table}
            len ={contentParts.length}
            open={openDialog}
            onClose={() => {
              setOpenDialog(false);
              setCurrentPart(null);
            }}
            onSave={handleSavePart}
            initialData={currentPart}
          />
        </Box>

        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          isSuccess={snackbarSuccess}
          handleClose={handleSnackbarClose}
        />
      </motion.div>
    </DndProvider>
  );
};
