import  { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  getProducts,
  GiftCardType,
  ProductType,
  rolesAdmin,
  useSecurityCheck,
} from "../../../utils";
import { AdminGiftCardEntry } from "./AdminGiftCardEntry";
import { CustomSnackbar, Header } from "../../../components";
import { motion } from "framer-motion";
import { useUtility } from "../../../utils/context/UtilityContext";
import {
  fetchGiftCardsAPi,
  createGiftCard,
  updateGiftCard,
} from "../../../utils";
import GiftCardStatistics from "./GiftCardStatistics";
import GroupedGiftCardsTable from "./GroupedGiftCardsTable";
import AllGiftCardsTable from "./AllGiftCardsTable";

export const GiftCardTable = () => {
  const securityCheck = useSecurityCheck(rolesAdmin);
  const { marginBottomHeader } = useUtility();

  const [giftCards, setGiftCards] = useState<GiftCardType[]>([]);
  const [groupedGiftCards, setGroupedGiftCards] = useState<any[]>([]);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentGiftCard, setCurrentGiftCard] = useState<GiftCardType | null>(null);
  const [stats, setStats] = useState({
    totalGiftCards: 0,
    totalSold: 0,
    totalValue: 0,
    totalRevenue: 0,
    totalPotentialRevenue: 0,
  });

  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    try {
      const products = await getProducts();
      setProducts(products);
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const calculateStats = useCallback(
    (giftCardsList: GiftCardType[]) => {
      let totalGiftCards = 0;
      let totalSold = 0;
      let totalValue = 0;
      let totalRevenue = 0;
      let totalPotentialRevenue = 0;

      giftCardsList.forEach((card) => {
        totalGiftCards += 1;
        totalSold += card.sold ? 1 : 0;
        totalValue += Number(card.cost);
        if (card.sold) {
          totalRevenue += Number(card.cost);
        }
        totalPotentialRevenue += Number(
          getProductSellingPrice(card.productId, products)
        );
      });

      setStats({
        totalGiftCards,
        totalSold,
        totalValue,
        totalRevenue,
        totalPotentialRevenue,
      });
    },
    [products]
  );

  const fetchGiftCards = useCallback(async () => {
    setLoading(true);
    try {
      const giftCardsList = await fetchGiftCardsAPi();
      setGiftCards(giftCardsList);
      groupGiftCards(giftCardsList);
      calculateStats(giftCardsList);
    } catch (error) {
      console.error("Failed to fetch gift cards:", error);
    } finally {
      setLoading(false);
    }
  }, [calculateStats]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    if (products.length > 0) {
      fetchGiftCards();
    }
  }, [products, fetchGiftCards]);

  const groupGiftCards = (giftCardsList: GiftCardType[]) => {
    const grouped = giftCardsList.reduce<{ [key: string]: any }>(
      (acc, card) => {
        const key = card.productId;
        if (!acc[key]) {
          acc[key] = {
            ...card,
            quantity: 0,
            quantitySold: 0,
            quantityNotSold: 0,
          };
        }
        acc[key].quantity += 1;
        if (card.sold) {
          acc[key].quantitySold += 1;
        } else {
          acc[key].quantityNotSold += 1;
        }
        return acc;
      },
      {}
    );

    setGroupedGiftCards(Object.values(grouped));
  };

  const handleAdd = () => {
    setCurrentGiftCard(null);
    setOpenDialog(true);
  };

  const handleSave = async (giftCard: GiftCardType) => {
    setLoading(true);
    try {
      if (giftCard.id) {
        await updateGiftCard(giftCard);
        setSnackbarMessage("Gift card updated successfully");
      } else {
        await createGiftCard(giftCard);
        setSnackbarMessage("Gift card created successfully");
      }
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage("Failed to save gift card");
      setSnackbarSuccess(false);
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
      fetchGiftCards();
      setOpenDialog(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (securityCheck) {
    return securityCheck;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll w-full`}
    >
      <Header />
      <Box className="w-5/6 p-8">
        <Typography variant="h4">Gift Card Inventory</Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "#133B62" }}
          onClick={handleAdd}
        >
          Ajouter un nouveau code
        </Button>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="400px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <GroupedGiftCardsTable
              groupedGiftCards={groupedGiftCards}
              products={products}
            />

            <AllGiftCardsTable
              giftCards={giftCards}
              products={products}
              refreshGiftCards={fetchGiftCards}
              setCurrentGiftCard={setCurrentGiftCard}
              setOpenDialog={setOpenDialog}
            />

            <GiftCardStatistics stats={stats} />
          </>
        )}

        <AdminGiftCardEntry
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onSave={handleSave} // This should trigger a refresh after saving
          currentGiftCard={currentGiftCard}
        />

        <CustomSnackbar
          open={snackbarOpen}
          message={snackbarMessage}
          isSuccess={snackbarSuccess}
          handleClose={handleSnackbarClose}
        />
      </Box>
    </motion.div>
  );
};

const getProductSellingPrice = (
  productId: string,
  products: ProductType[]
) => {
  const product = products.find((prod) => prod.id === productId);
  return product ? product.priceUSD : "0";
};
