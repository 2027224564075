import { useNavigate } from "react-router-dom";
import logo from "../../assets/Bsb.png";

export const GotoLogin = () => {
  const navigate = useNavigate();
  return (
    <div className="flex h-screen justify-center items-center">
      <div className="text-center">
        <img src={logo} alt="Logo" className="w-1/3 mx-auto mb-4" />

        <p className="text-lg">Veuillez vous connecter</p>
        <button
          className="border-4 h-16 w-64 m-5 rounded-lg bg-primary text-white text-3xl"
          onClick={() => navigate("/login")}
        >
          se connecter
        </button>
      </div>
    </div>
  );
};
