import { ProductType } from "../../types";
import { apiSrcUrl } from "../ApiRoutes";


const token = localStorage.getItem("token");

const headers = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${token}`
};

export const postProduct = async (product: Omit<ProductType, 'id'>): Promise<any> => {
  const response = await fetch(`${apiSrcUrl}/private/product`, {
    method: 'POST',
    headers,
    body: JSON.stringify(product),
  });

  if (!response.ok) {
    throw new Error('Failed to post product');
  }

  return await response.json();
};

export const putProduct = async (product: ProductType): Promise<any> => {
  const response = await fetch(`${apiSrcUrl}/private/product`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(product),
  });

  if (!response.ok) {
    throw new Error('Failed to update product');
  }

  return await response.json();
};

export const deleteProductOnTheServer = async (id: string): Promise<any> => {
  const response = await fetch(`${apiSrcUrl}/private/product`, {
    method: 'DELETE',
    headers,
    body: JSON.stringify({ id }),
  });

  if (!response.ok) {
    throw new Error('Failed to delete product');
  }

  return await response.json();
};
