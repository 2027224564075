import React, { useState } from "react";
import {
  Menu,
  MenuItem,
  Divider,
  Typography,
  Box,
  ListItemIcon,
} from "@mui/material";
import { useAuth } from "../../../utils/context/authContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoginIcon from "@mui/icons-material/Login";
import { useNavigate } from "react-router-dom";
import { UserAvatar } from "./Header";

export const UserAvatarMenu: React.FC = () => {
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
    navigate("/login");
  };

  const handleLogin = () => {
    handleClose();
    navigate("/login");
  };

  const handleNavigate = (path: string) => {
    handleClose();
    navigate(path);
  };

  return (
    <Box>
      <div
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        className="flex items-center justify-center"
      >
        <UserAvatar user={user} isLogged={true} />
      </div>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {user ? (
          <div>
            <MenuItem>
              <Typography variant="h6">{user?.username}</Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handleNavigate("/account/account")}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              Mon compte
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/account/balance")}>
              <ListItemIcon>
                <AccountBalanceWalletIcon fontSize="small" />
              </ListItemIcon>
              Solde : {user?.amount} BSB Coins
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/account/key-library")}>
              <ListItemIcon>
                <VpnKeyIcon fontSize="small" />
              </ListItemIcon>
              Bibliothèque de clés
            </MenuItem>
            <MenuItem onClick={() => handleNavigate("/account/settings")}>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              Paramètres
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              Se déconnecter
            </MenuItem>
          </div>
        ) : (
          <MenuItem onClick={handleLogin}>
            <ListItemIcon>
              <LoginIcon fontSize="small" />
            </ListItemIcon>
            Se connecter
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
