import { Footer, GotoLogin, Header, Loading } from "../../shared";
import { useDecodeToken } from "../../../utils";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/context/authContext";
import { useUtility } from "../../../utils/context/UtilityContext";
import { UserTable } from "./UserTable";

export const UserManagement = () => {
  const { userFromToken, token } = useDecodeToken();
  const [Logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  const { login } = useAuth();
  const { marginBottomHeader } = useUtility();
  useEffect(() => {
    const handleLogin = async () => {
      if (token) {
        setLogged(true);
      }
      if (userFromToken) {
        login(userFromToken);
      }
      setLoading(false);
    };
    handleLogin();
  }, [token, userFromToken, login]);

  if (loading) {
    return <Loading />;
  }

  if (!Logged) {
    return <GotoLogin />;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4`}
    >
      <Header />
      <UserTable />
      <Footer />
    </motion.div>
  );
};
