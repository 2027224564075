// BalanceSection.tsx
import React, { useEffect, useState } from 'react';
import { Typography, Box, Divider, Paper } from '@mui/material';
import { getUserByID, User } from '../../utils';
import { Loading } from '../../components';
import { useAuth } from '../../utils/context';

export const BalanceSection: React.FC = () => {
  const { user: authUser } = useAuth();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (authUser) {
        const fetchedUser = await getUserByID(authUser.userID);
        setUser(fetchedUser);
      }
    };
    fetchUserData();
  }, [authUser]);

  if (!user) {
    return <Loading/>;
  }
  return (
    <Box p={3}>
      <Typography variant="h5">Solde</Typography>
      <Divider sx={{ my: 2 }} />
      <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Solde Total</Typography>
        <Typography variant="h4" color="primary">{user.amount.toFixed(2)} BSB Coins</Typography>
        <Typography variant="body2">Montant total de tous les soldes estimé sur la base du taux de conversion le plus récent.</Typography>
      </Paper>
    </Box>
  );
};
