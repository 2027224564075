import { getToken } from "utils/helper";
import { GiftCardType } from "utils/types";
import { apiSrcUrl } from "../ApiRoutes";



/**
 * Fetch all gift cards from the API
 */
export const fetchGiftCardsAPi = async (): Promise<GiftCardType[]> => {
  const token = getToken();
  if (!token) {
    throw new Error('Authorization token is not available');
  }

  const response = await fetch(`${apiSrcUrl}/private/giftcard`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch gift cards');
  }

  return response.json();
};

/**
 * Create a new gift card
 */
export const createGiftCard = async (giftCard: GiftCardType): Promise<GiftCardType> => {
  const token = getToken();
  if (!token) {
    throw new Error('Authorization token is not available');
  }

  const response = await fetch(`${apiSrcUrl}/private/giftcard`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(giftCard),
  });

  if (!response.ok) {
    throw new Error('Failed to create gift card');
  }

  return response.json();
};

/**
 * Update a gift card
 */
export const updateGiftCard = async (giftCard: GiftCardType): Promise<void> => {
  const token = getToken();
  if (!token) {
    throw new Error('Authorization token is not available');
  }
  if (!giftCard.id) {
    throw new Error('Gift card ID is required for update');
  }

  const response = await fetch(`${apiSrcUrl}/private/giftcard/${giftCard.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(giftCard),
  });

  if (!response.ok) {
    throw new Error('Failed to update gift card');
  }
};

/**
 * Delete a gift card
 */
export const deleteGiftCard = async (id: string): Promise<void> => {
  const token = getToken();
  if (!token) {
    throw new Error('Authorization token is not available');
  }

  const response = await fetch(`${apiSrcUrl}/private/giftcard/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  if (!response.ok) {
    throw new Error('Failed to delete gift card');
  }
};
