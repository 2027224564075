import { apiSrcUrl } from "../ApiRoutes";



export const postPageContent = async (pageName: string, content: any) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${apiSrcUrl}/private/pages/${pageName}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(content),
    });

    if (!response.ok) {
      throw new Error(`Failed to post page content: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error posting page content:", error);
    return { success: false, message: "Error posting page content" };
  }
};

export const putPageContent = async (pageName: string, content: any) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${apiSrcUrl}/private/pages/${pageName}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(content),
    });

    if (!response.ok) {
      throw new Error(`Failed to update page content: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating page content:", error);
    return { success: false, message: "Error updating page content" };
  }
};

export const deletePageContent = async (pageName: string, id: string) => {
  const token = localStorage.getItem("token");
  try {
    const response = await fetch(`${apiSrcUrl}/private/pages/${pageName}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      throw new Error(`Failed to delete page content: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error deleting page content:", error);
    return { success: false, message: "Error deleting page content" };
  }
};
