import { apiSrcUrl } from "../ApiRoutes";

// Function to fetch all messages with a token
export const fetchAllMessages = async (token: string) => {
  try {
    const res = await fetch(`${apiSrcUrl}/private/sms`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (error) {
    console.error("Error fetching all messages:", error);
    return { error: "Failed to fetch messages." };
  }
};

// Function to fetch a single SMS by ID with a token
export const fetchSingleMessage = async (id: string, token: string) => {
  try {
    const res = await fetch(`${apiSrcUrl}/private/sms/${id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    return await res.json();
  } catch (error) {
    console.error("Error fetching single message:", error);
    return { error: `Failed to fetch message with ID: ${id}.` };
  }
};
