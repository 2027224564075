import { ProviderType } from "utils/types";
import { apiSrcUrl } from "../ApiRoutes";

export const postInfo = async (
  infos: Omit<any, "id">,
  route: string
): Promise<any> => {
  let body: any;
  let headers: HeadersInit = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  switch (route) {
    case "providers":
      const formData = prepareProvider(infos);
      body = formData;
      break;
    case "steps":
    case "motto":
    case "bestseller":
      body = JSON.stringify(infos);
      headers["Content-Type"] = "application/json";
      break;
    default:
      body = JSON.stringify(infos);
      headers["Content-Type"] = "application/json";
      break;
  }
  const response = await fetch(`${apiSrcUrl}/private/info/${route}`, {
    method: "POST",
    headers: headers,
    body: body,
  });

  if (!response.ok) {
    throw new Error("Failed to post provider");
  }

  const data = await response.json();
  return data;
};

const prepareProvider = (infos: Omit<ProviderType, "id">): FormData => {
  const formData = new FormData();
  formData.append("description", infos.description);
  formData.append("name", infos.name);
  formData.append("image", infos.url);
  infos.qas.forEach((qa, index) => {
    formData.append(`qas[${index}][question]`, qa.question);
    formData.append(`qas[${index}][answer]`, qa.answer);
  });
  return formData;
};

export const putInfo = async (infos: any, route: string): Promise<any> => {
  console.log("Info to update :", infos)
  const response = await fetch(`${apiSrcUrl}/private/info/${route}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(infos),
  });

  if (!response.ok) {
    throw new Error("Failed to update provider");
  }

  const data = await response.json();
  return data;
};

export const deleteInfo = async (id: string, route: string): Promise<any> => {
  const response = await fetch(`${apiSrcUrl}/private/info/${route}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ id }),
  });

  if (!response.ok) {
    throw new Error("Failed to delete provider");
  }

  const data = await response.json();
  return data;
};
