import React, { useState } from "react";
import { Box, CssBaseline, Drawer, Toolbar, IconButton } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { Header } from "../shared";
import { AccountTabs } from "./AccountTabs";
import { useWindowSize } from "../../utils";
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;

export const MainLayout: React.FC = () => {
  const { isMobile } = useWindowSize();
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Box sx={{ display: "flex",boxSizing:'border-box' }}width={'100%'} overflow={'hidden'}>
      <CssBaseline />
      <Header secondPart={false} />
      <Drawer
        variant="permanent"
        open={sidebarOpen}
        sx={{
          width: sidebarOpen ? drawerWidth : 60,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: sidebarOpen ? drawerWidth : 60,
            marginTop: "64px",
            boxSizing: "border-box",
            backgroundColor: "#0C253E",
            color: "white",
            zIndex: 0,
            transition: "width 0.3s",
            overflowX: "hidden",
          },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSidebar}
            edge="start"
            sx={{ marginRight: 2, display: isMobile ? 'block' : 'none' }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Sidebar sidebarOpen={sidebarOpen} />
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3, marginTop: "64px", overflowX: 'hidden',width:'100%' }}
      >
        <AccountTabs />
        <Outlet />
      </Box>
    </Box>
  );
};
