import  { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Loading } from "./Loading";
import {  Typography, Box, Paper, CardMedia } from "@mui/material";

import { fetchPublic, MottoType, useWindowSize } from "../../utils";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0 },
};

export const Motto = () => {
  const [mottos, setMottos] = useState<MottoType[]>([]);
  const { isMobile, isPC } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState<number[]>([]);
  const controls = useAnimation();
  const { ref: mottoRef, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    const fetchMottos = async () => {
      const result = await fetchPublic(`/public/info/mottos`);
    if (result.info) {
      setMottos(Object.values(result.info));
    }
      setLoading(false);
    };

    fetchMottos();
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const handleExpandClick = (index: number) => {
    if (expanded.includes(index)) {
      setExpanded(expanded.filter((i) => i !== index));
    } else {
      setExpanded([...expanded, index]);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        padding: "16px",
        margin: "16px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <motion.div
        ref={mottoRef}
        className="flex w-full items-center justify-between  flex-col"
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        {mottos.map((motto, index) => (
          <motion.div
            key={index}
            className="flex gap-4 items-center justify-center  flex-row w-full "
            variants={itemVariants}
          >
            {isPC && (
              <Box
                className="w-1/5 h-full bg-gray"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CardMedia
                  component="img"
                  image={
                    "https://firebasestorage.googleapis.com/v0/b/bsb-project-99087.appspot.com/o/images%2Flogos%2FLogoWithNames.jpg?alt=media&token=7d8f0f73-90b8-47f5-bc5a-724589b27512"
                  }
                  alt={"logoWithName"}
                  sx={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                />
              </Box>
            )}

            <Box
              className={`flex flex-col ${
                isMobile ? "w-full" : "w-4/5"
              } items-center justify-center `}
              sx={{ width: isMobile ? "100%" : "75%" }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  cursor: "pointer",
                }}
                onClick={() => handleExpandClick(index)}
              >
                <Paper
                className=" bg-gray"
                  elevation={3}
                  sx={{
                    padding: "16px",
                    margin: "16px",
                    width: isMobile ? "100%" : "auto",
                    background:'#f5f5f5'
                  }}
                >
                  <Typography variant="h6" textAlign={"justify"}  dangerouslySetInnerHTML={{ __html: motto.motto }}/>
                    
                 
                </Paper>
              </Box>
            </Box>
          </motion.div>
        ))}
      </motion.div>
    </Box>
  );
};
