import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchPublic, ProductType } from "utils";
import { Loading } from "../Loading";
import { useUtility } from "utils/context/UtilityContext";
import { motion } from "framer-motion";
import { Header } from "../Header";
import { Footer } from "../footer";
import { Subtitle } from "../Subtitle";
import { ProviderList } from "./ProviderList";
import { Button, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

export const CategoryPage = () => {
  const { category } = useParams<{ category: string }>();
  console.log("Category page:", category);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const { marginBottomHeader } = useUtility();
  const navigate = useNavigate();

  const fetchProducts = useCallback(async () => {
    setLoading(true);
    const result = await fetchPublic(`/public/product/${category}`);
    console.log(result);
    if (result) {
      const productsArray = Object.values(result) as ProductType[];
      setProducts(productsArray);
    } else {
      setProducts([]);
    }
    setLoading(false);
  }, [category]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);
  if (loading) {
    return <Loading />;
  }

  const handleSelectProvider = (provider: string) => {
    navigate(`/provider/${provider}`);
  };

  const handleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleBack = () => {
    navigate(-1);
  };

  const categories = Array.from(
    new Set(products.map((product) => product.category))
  );

  const providersByCategory = categories.map((cat) => {
    const providers = Array.from(
      new Set(
        products
          .filter((product) => product.category === cat)
          .map((product) => product.provider)
      )
    );
    const providerImages = Array.from(
      new Set(
        products
          .filter((product) => product.category === cat)
          .map((product) => ({
            provider: product.provider,
            image: product.image,
          }))
      )
    );

    const sortedProviders = providers.sort((a, b) => {
      if (sortOrder === "asc") {
        return a.localeCompare(b);
      } else {
        return b.localeCompare(a);
      }
    });

    return {
      category: cat,
      providers: sortedProviders,
      providerImages,
    };
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll`}
    >
      <Header />
      <div
        className={` flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll p-8 w-full`}
      >
        <Button variant="contained" onClick={handleSort}>
          Trier par nom ({sortOrder === "asc" ? "A-Z" : "Z-A"})
        </Button>
        {products.length === 0 ? (
          <div className="flex flex-col items-center justify-center gap-4 mt-8">
            <SentimentDissatisfiedIcon
              style={{ fontSize: 50, color: "gray" }}
            />
            <Typography variant="h6" className="text-gray-700">
              Rien à trouver sur cette page 😔
            </Typography>
            <Button variant="contained" onClick={handleBack}>
              Aller à la page précédente
            </Button>
          </div>
        ) : (
          providersByCategory.map(({ category, providers, providerImages }) => (
            <div key={category} className="w-full">
              <Subtitle title={category} />
              <ProviderList
                providers={providers}
                providerImages={providerImages}
                onSelectProvider={handleSelectProvider}
              />
            </div>
          ))
        )}
      </div>
      <Footer />
    </motion.div>
  );
};
