import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Toolbar,
  Typography,
  Box,
  TableSortLabel,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  SupervisedUserCircle as RoleIcon,
  AttachMoney as AmountIcon,
} from "@mui/icons-material";
import {
  getUsers,
  deleteUser,
  useSecurityCheck,
  rolesAdmin,
  UserWithPromoCode,
  getUserDetails,
} from "../../../utils";
import { UserDialog } from "./UserDialog";
import { RoleDialog } from "./RoleDialog";
import { AmountDialog } from "./AmountDialog";
import { CustomSnackbar } from "../../shared";

const headCells = [
  { id: "username", numeric: false, label: "Nom d'utilisateur" },
  { id: "email", numeric: false, label: "Email" },
  { id: "age", numeric: true, label: "Âge" },
  { id: "gender", numeric: false, label: "Genre" },
  { id: "role", numeric: false, label: "Rôle" },
  { id: "amount", numeric: true, label: "Montant" },
  {
    id: "promoCodeUsageCount",
    numeric: true,
    label: "Utilisation de code promo",
  },
  { id: "isConfirmed", numeric: false, label: "Confirmé" },
  { id: "actions", numeric: false, label: "Actions" },
];

export const UserTable: React.FC = () => {
  const securityCheck = useSecurityCheck(rolesAdmin);
  const [users, setUsers] = useState<UserWithPromoCode[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof UserWithPromoCode>("username");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const [openAmountDialog, setOpenAmountDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState<UserWithPromoCode | null>(
    null
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

  const fetchUsersWithPromoCodeUsage = useCallback(async () => {
    setLoading(true);
    try {
      const result: UserWithPromoCode[] = await getUsers();
      const usersWithPromoCode: UserWithPromoCode[] = await Promise.all(
        result.map(async (user) => {
          const promoCodeUsageCount = await fetchPromoCodeUsageCount(user.userID);
          return { ...user, promoCodeUsageCount };
        })
      );
      setUsers(usersWithPromoCode);
    } catch (error) {
      console.error('Error fetching users with promo code usage:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUsersWithPromoCodeUsage();
  }, [fetchUsersWithPromoCodeUsage]);

  const fetchPromoCodeUsageCount = async (userID: string): Promise<number> => {
    try {
      return await getUserDetails(userID, "promo");
    } catch (error) {
      console.error(
        `Failed to fetch promo code usage count for user ${userID}:`,
        error
      );
      return 0; // or handle the error in a way that makes sense for your application
    }
  };

  const handleRequestSort = (property: keyof UserWithPromoCode) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (id: string) => {
    await deleteUser(id);
    fetchUsersWithPromoCodeUsage();
    setSnackbarMessage("Utilisateur supprimé avec succès!");
    setSnackbarSuccess(true);
    setSnackbarOpen(true);
  };

  const handleEdit = (user: UserWithPromoCode) => {
    setCurrentUser(user);
    setOpenDialog(true);
  };

  const handleRole = (user: UserWithPromoCode) => {
    setCurrentUser(user);
    setOpenRoleDialog(true);
  };

  const handleAmount = (user: UserWithPromoCode) => {
    setCurrentUser(user);
    setOpenAmountDialog(true);
  };

  const handleAdd = () => {
    setCurrentUser(null);
    setOpenDialog(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const sortedUsers = users.sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (aValue === undefined || bValue === undefined) {
      return 0;
    }

    if (order === "asc") {
      return aValue < bValue ? -1 : 1;
    }
    return aValue > bValue ? -1 : 1;
  });

  if (securityCheck) {
    return securityCheck;
  }

  return (
    <Box sx={{ width: "95%", marginTop: "55px" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar>
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Utilisateurs
          </Typography>
          <Button variant="contained" color="primary" onClick={handleAdd}>
            Ajouter un utilisateur
          </Button>
        </Toolbar>
        <TableContainer>
          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="400px"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={() =>
                          handleRequestSort(
                            headCell.id as keyof UserWithPromoCode
                          )
                        }
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user) => (
                    <TableRow hover tabIndex={-1} key={user.userID}>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.age}</TableCell>
                      <TableCell>{user.gender}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>{user.amount}</TableCell>
                      <TableCell>{user.promoCodeUsageCount}</TableCell>
                      <TableCell>
                        {user.isConfirmed ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Modifier">
                          <IconButton onClick={() => handleEdit(user)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Supprimer">
                          <IconButton onClick={() => handleDelete(user.userID)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Modifier le rôle">
                          <IconButton onClick={() => handleRole(user)}>
                            <RoleIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Gérer le montant">
                          <IconButton onClick={() => handleAmount(user)}>
                            <AmountIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <UserDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSave={fetchUsersWithPromoCodeUsage}
        initialData={currentUser}
      />
      <RoleDialog
        open={openRoleDialog}
        onClose={() => setOpenRoleDialog(false)}
        onSave={fetchUsersWithPromoCodeUsage}
        initialData={currentUser}
      />
      <AmountDialog
        open={openAmountDialog}
        onClose={() => setOpenAmountDialog(false)}
        onSave={fetchUsersWithPromoCodeUsage}
        initialData={currentUser}
      />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </Box>
  );
};