import { DecodedUser, ProductType, User } from "../types";
import sign from "jwt-encode";
import { jwtDecode } from "jwt-decode";
import Flag from "react-flagkit";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, Tooltip } from "@mui/material";

const secret = process.env.REACT_APP_SECRET_JWT;

if (!secret) {
  throw new Error(
    "REACT_APP_SECRET_JWT is not defined in environment variables"
  );
}

export const EncodeToken = (data: any) => {
  if (typeof data !== "object" || data === null) {
    throw new TypeError("Data to be encoded must be a non-null object");
  }

  const currentTime = Math.floor(Date.now() / 1000);
  const expirationTime = currentTime + 10800;
  const { cart, transactions, ...rest } = data;
  const payload = {
    ...rest,
    iat: currentTime,
    exp: expirationTime,
  };
  console.log("Payload to encode : ", payload);
  try {
    const token = sign(payload, secret);
    console.log("created token");
    return token;
  } catch (error) {
    console.error("Failed to sign JWT:", error);
    return null;
  }
};

export const DecodeToken = (token: string) => {
  if (!token) {
    throw new Error("Token must be provided for decoding");
  }

  try {
    const decoded = jwtDecode<DecodedUser>(token);
    console.log("Decoded token", decoded);

    // Validate the decoded token contains all required fields
    if (
      decoded.userID &&
      decoded.username &&
      decoded.email &&
      typeof decoded.age === "number" &&
      typeof decoded.amount === "number" &&
      decoded.role &&
      typeof decoded.isConfirmed === "boolean" &&
      typeof decoded.iat === "number" &&
      typeof decoded.exp === "number"
    ) {
      return decoded;
    } else {
      console.error("Decoded token missing required fields");
      return null;
    }
  } catch (error) {
    console.error("Token verification failed:", error);
    return null;
  }
};

export const getLocalStorage = (key: string): string | null => {
  const element = localStorage.getItem(key);
  if (!element || element.match("null")) return null;
  return element;
};

export const getToken = (): string | null => {
  const token = getLocalStorage("token");
  if (!token) {
    return null;
  }

  try {
    const payload = JSON.parse(atob(token.split(".")[1]));
    const isExpired = payload.exp < Date.now() / 1000;
    if (isExpired) {
      console.log("removing token ...");
      localStorage.removeItem("token");
      return null;
    }
    return token;
  } catch (error) {
    console.error("Invalid token:", error);
    localStorage.removeItem("token");
    return null;
  }
};

export const checkIfUserValid = (user: User | null): boolean => {
  if (!user || user === null) return false;

  if (!user.email.includes("@") || user.age <= 5 || user.age > 99) return false;

  return true;
};

interface DecodeUserProps {
  decodedUser: DecodedUser | null;
}

export const setUserFromToken = ({
  decodedUser,
}: DecodeUserProps): User | null => {
  if (!decodedUser) return null;

  const user: User = {
    userID: decodedUser.userID,
    username: decodedUser.username,
    email: decodedUser.email,
    avatarUrl: decodedUser.avatarUrl,
    gender: decodedUser.gender,
    age: decodedUser.age,
    amount: decodedUser.amount,
    role: decodedUser.role,
    isConfirmed: decodedUser.isConfirmed,
  };

  return user;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(
    () => {
      console.log("Successfully copied to clipboard");
    },
    (err) => {
      console.error("Failed to copy text to clipboard", err);
    }
  );
};

export const getProductDetails = (
  productId: string,
  products: ProductType[]
) => {
  const product = products.find((product) => product.id === productId);
  if (product) {
    return (
      <div>
        {product.name} {product.price} {product.currency}{" "}
        <Flag country={product.countryCode} />
      </div>
    );
  }
  return <div>No product assigned</div>;
};

export const getProductPrice = (productId: string, products: ProductType[]) => {
  const product = products.find((product) => product.id === productId);
  if (product) {
    return <div>{product.priceUSD} $</div>;
  }
  return <div>No product assigned</div>;
};
export const formatDate = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export const getYoutubeEmbedUrl = (url: string) => {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? `https://www.youtube.com/embed/${match[1]}` : url;
};

export const convertFileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      if (result.startsWith("data:image/")) {
        const base64String = result.split(",")[1];
        resolve(base64String);
      } else {
        reject(new Error("Invalid image format"));
      }
    };
    reader.onerror = (error) => reject(error);
  });
};

export const sanitizePath = (path: string): string => {
  return path.replace(/[.#$[\]]/g, "_");
};

export const splitAndSanitizePath = (fullPath: string) => {
  const parts = fullPath.split("/");
  const basePath = parts[0]; // 'images'
  const restPath = parts.slice(1).join("/");
  return {
    basePath: sanitizePath(basePath),
    restPath: sanitizePath(restPath),
  };
};

interface SetFlagProps {
  countryCode: string;
  countryName: string;
}

export const SetFlag: React.FC<SetFlagProps> = ({ countryCode, countryName }) => {
  if (countryCode.toLowerCase() === "global") {
    return (
      <Tooltip title="Mondial" placement="top-end">
        <Box>
          <LanguageIcon />
        </Box>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={countryName} placement="top-end">
      <Box>
        <Flag country={countryCode} />
      </Box>
    </Tooltip>
  );
};
