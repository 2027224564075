// OrdersSection.tsx
import React from 'react';
import { Typography, Box, Divider } from '@mui/material';

export const OrdersSection: React.FC = () => {
  return (
    <Box p={3}>
      <Typography variant="h5">Commandes</Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body1">Liste des commandes...</Typography>
      {/* Add more details here */}
    </Box>
  );
};
