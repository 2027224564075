import { convertFileToBase64 } from "utils/helper";
import { apiSrcUrl } from "../ApiRoutes";

export const fetchUploadImage = async (
  file: File,
  path: string,
  token: string,
  route: string,
  pathInDb: string
) => {
  const base64String = await convertFileToBase64(file);
  const mimeType = file.type;
  const imageData = new FormData();
  imageData.append("base64String", base64String);
  imageData.append("mimeType", mimeType);
  imageData.append("path", path);
  imageData.append("pathInDb", pathInDb);
  const response = await fetch(`${apiSrcUrl}${route}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: imageData,
  });

  if (!response.ok) {
    throw new Error("Failed to upload image");
  }

  const data = await response.json();
  return data;
};

export const updateImageOnServer = async (
  url: string,
  path: string,
  route: string,
  pathInDb: string
) => {
  try {
    const response = await fetch(`${apiSrcUrl}${route}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ url, path, pathInDb }),
    });

    if (!response.ok) {
      throw new Error("Failed to update image on server");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating image on server:", error);
    throw error;
  }
};

export const deleteImageFromServer = async (
  pathInDb: string,
  token: string,
  storagePath: string
) => {
  try {
    const response = await fetch(`${apiSrcUrl}/private/images`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ pathInDb, storagePath }),
    });

    if (!response.ok) {
      throw new Error("Failed to delete image");
    }

    return await response.json();
  } catch (error) {
    console.error("Failed to delete image", error);
    throw error;
  }
};

export const fetchImagesFromServer = async (token: string) => {
  try {
    const response = await fetch(`${apiSrcUrl}/private/images`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const text = await response.text();
      throw new Error(`Failed to fetch images: ${text}`);
    }

    const data = await response.json();
    return { success: true, images: data.images };
  } catch (error) {
    console.error("Error fetching image on server:", error);
    return { success: false, message: "Error fetching image on server " };
  }
};