import { getToken } from "utils/helper";
import { apiSrcUrl } from "../ApiRoutes";
import { User } from "utils/types";

export const postUserDetails = async (
  object: any,
  route: string,
  userID: string
): Promise<any> => {
  let body: any;
  let headers: HeadersInit = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  switch (route) {
    case "promocode-apply":
      body = JSON.stringify({
        promoCode: object.promoCode,
        totalWithoutDiscount: object.totalWithoutDiscount,
      });
      headers["Content-Type"] = "application/json";
      break;
    case "proceed-transaction":
      console.log("Proceding");
      body = JSON.stringify({
        item: object.item,
        totalTransaction: object.totalTransaction,
        promoCode: object.promoCode,
        promoCodeType: object.promoCodeType,
        isPromoCodeUsed: object.isPromoCodeUsed,
      });
      headers["Content-Type"] = "application/json";
      break;
    case "save-cart":
      body = JSON.stringify({ cart: object.cart });
      headers["Content-Type"] = "application/json";
      break;

    case "get-cart":
      body = JSON.stringify({});
      headers["Content-Type"] = "application/json";
      break;

    case "clear-cart":
      body = JSON.stringify({});
      headers["Content-Type"] = "application/json";
      break;
    case "is-username-unique":
      body = JSON.stringify({ username: object.username });
      headers["Content-Type"] = "application/json";
      break;
    case "transactions-by-timestamp":
      body = JSON.stringify({ timestamp: object.timestamp });
      headers["Content-Type"] = "application/json";
      break;
    default:
      body = JSON.stringify(object);
      headers["Content-Type"] = "application/json";
      break;
  }
  const response = await fetch(
    `${apiSrcUrl}/private/users/${userID}/${route}`,
    {
      method: "POST",
      headers: headers,
      body: body,
    }
  );
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }

  return data;
};

export const getUsers = async (): Promise<any[]> => {
  const token = getToken();
  if(!token){
    throw new Error(`Error getting token`);
  }
  const response = await fetch(`${apiSrcUrl}/private/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Error getting users: ${response.statusText}`);
  }

  return response.json();
};

export const getUserByID = async (userID: string): Promise<User | null> => {
  const token = getToken();
  const response = await fetch(`${apiSrcUrl}/private/users/${userID}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Error getting user: ${response.statusText}`);
  }

  return response.json();
};

export const getUserDetails = async (
  userID: string,
  type: string
): Promise<any | null> => {
  const token = getToken();
  const response = await fetch(`${apiSrcUrl}/private/users/${userID}/${type}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Error getting user: ${response.statusText}`);
  }

  return response.json();
};

export const updateUser = async (userID: string, user: User): Promise<void> => {
  const token = getToken();
  const response = await fetch(`${apiSrcUrl}/private/users/${userID}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(user),
  });

  if (!response.ok) {
    throw new Error(`Error updating user: ${response.statusText}`);
  }
};

export const UpdateUserDetails = async (
  object: any,
  route: string,
  userID: string
): Promise<any> => {
  let body: any;
  let headers: HeadersInit = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  switch (route) {
    case "amount":
      
      body = JSON.stringify({
        amount: object.amount,
        userIDAdmin: object.userIDAdmin,
        userIDUpdateUser: object.userIDUpdateUser,
      });
      headers["Content-Type"] = "application/json";
      break;
    case "role":
      body = JSON.stringify({
        role: object.role,
        userIDAdmin: object.userIDAdmin,
        userIDUpdateUser: object.userIDUpdateUser,
      });
      headers["Content-Type"] = "application/json";
      break;
    default:
      body = JSON.stringify(object);
      headers["Content-Type"] = "application/json";
      break;
  }
  const response = await fetch(
    `${apiSrcUrl}/private/users/${userID}/${route}`,
    {
      method: "PUT",
      headers: headers,
      body: body,
    }
  );

  if (!response.ok) {
    throw new Error("Failed to post provider");
  }

  const data = await response.json();
  return data;
};

export const deleteUser = async (userID: string): Promise<void> => {
  const token = getToken();
  const response = await fetch(`${apiSrcUrl}/private/users/${userID}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Error deleting user: ${response.statusText}`);
  }
};
