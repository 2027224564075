import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  IconButton,
  TextField,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Flag from "react-flagkit";
import { useUtility } from "../../../utils/context/UtilityContext";
import { useNavigate } from "react-router-dom";

interface CartDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CartDialog: React.FC<CartDialogProps> = ({ open, onClose }) => {
  const { cart, removeFromCart, updateCartQuantity } = useUtility();
  const navigate = useNavigate();

  const calculateTotal = () => {
    return cart.reduce(
      (total, item) => total + item.product.priceUSD * item.quantity,
      0
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Mon panier</DialogTitle>
      <DialogContent>
        {cart.length === 0 ? (
          <Box textAlign="center" py={3}>
            <Typography variant="body1">Votre panier est vide</Typography>
            <Typography variant="body2">
              Il semble que vous n’avez pas encore choisi de produit
            </Typography>
          </Box>
        ) : (
          <List>
            {cart.map((item, index) => (
              <ListItem key={index} alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar src={item.product.image} alt={item.product.name} />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      {item.product.name} {item.product.price}{" "}
                      {item.product.currency}{" "}
                      <Flag country={item.product.countryCode} />
                    </>
                  }
                  secondary={
                    <Box display="flex" alignItems="center" mt={1}>
                      <Typography variant="body2">Quantité: </Typography>
                      <TextField
                        type="number"
                        value={item.quantity}
                        onChange={(e) =>
                          updateCartQuantity(
                            item.product.id,
                            parseInt(e.target.value)
                          )
                        }
                        inputProps={{ min: 1 }}
                        style={{ width: "50px", marginLeft: "8px" }}
                      />
                      <IconButton
                        onClick={() => removeFromCart(item.product.id)}
                        style={{ marginLeft: "8px" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                />
                <Typography variant="body2">{item.product.priceUSD} BSB Coins</Typography>
              </ListItem>
            ))}
            <Divider />
            <ListItem>
              <ListItemText primary="Total" />
              <Typography variant="body2">{calculateTotal().toFixed(2)} BSB Coins</Typography>
            </ListItem>
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fermer
        </Button>
        {cart.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/cart-summary")}
          >
            Passer à la caisse
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
