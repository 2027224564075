import { useNavigate } from "react-router-dom";
import {
  getProducts,
  ProductType,
  SearchIcon,
  useWindowSize,
} from "../../../utils";
import { useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  ClickAwayListener,
  CardMedia,
} from "@mui/material";
import Flag from "react-flagkit";

interface Props {
  onSearch: (query: string) => void;
}

export const SearchBar: React.FC<Props> = ({ onSearch }) => {
  const [query, setQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<ProductType[]>([]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { isMobile, isTablet, isPC } = useWindowSize();
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    if (event.target.value.length > 2) {
      const results = await getProducts();
      const filteredResults = results.filter(
        (product) =>
          product.name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          product.category
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          product.provider
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
      );
      setSearchResults(filteredResults);
      setIsSearching(true);
    } else {
      setSearchResults([]);
      setIsSearching(false);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSearch(query);
  };

  const handleIconClick = () => {
    if (isMobile || isTablet) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleProductClick = (provider: string) => {
    navigate(`/provider/${provider}`);
    setIsExpanded(false);
    setQuery("");
    setIsSearching(false);
  };

  const handleClickAway = () => {
    setIsExpanded(false);
    setQuery("");
    setIsSearching(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <form
        onSubmit={handleSubmit}
        className={`flex items-center ${
          isPC ? "w-2/5" : isExpanded ? "w-full" : "w-auto"
        } items-center justify-center`}
        style={{ position: "relative" }}
      >
        {isPC && (
          <input
            type="text"
            placeholder="Chercher un produit..."
            value={query}
            onChange={handleChange}
            className="border-none rounded-l-lg w-4/5 p-2 box-border text-black"
          />
        )}
        <button
          type="button"
          aria-label="Search"
          className="bg-transparent text-black flex justify-center items-center flex-row rounded-r-lg gap-2 p-2 box-border"
        >
          <div
            onClick={handleIconClick}
            className={`flex justify-center items-center flex-row rounded p-2 box-border ${
              isPC && "bg-black"
            }`}
          >
            <SearchIcon
              style={{ fill: "white", width: "40px", height: "40px" }}
            />
          </div>

          {isExpanded && (
            <div className="flex fixed top-0 left-0 right-0 bottom-0 z-40 justify-center items-center bg-black h-16 text-black">
              <div className="flex items-center w-full max-w-lg mx-auto p-2">
                <input
                  type="text"
                  placeholder="Chercher un produit..."
                  value={query}
                  onChange={handleChange}
                  className="border-none rounded-l-lg flex-grow p-2 box-border text-black"
                />
                <div
                  onClick={handleClickAway}
                  className="flex text-7xl text-white cursor-pointer justify-center items-center text-center p-2 h-full"
                >
                  &times;
                </div>
              </div>
            </div>
          )}
        </button>

        {query && isSearching && (
          <Paper
            style={{
              width: isPC ? "40vw" : "80vw",
              position: "absolute",
              top: "100%",
              left: "50%",
              transform: isPC ? "translateX(-50%)" : "translateX(-20%)",
              zIndex: 50,
            }}
          >
            <List>
              {searchResults.length === 0 ? (
                <ListItem>
                  <ListItemText primary="Pas de résultats" />
                </ListItem>
              ) : (
                searchResults.map((product) => (
                  <ListItem
                    button
                    key={product.id}
                    onClick={() => handleProductClick(product.provider)}
                    sx={{
                      width: "100%",
                      borderRadius: "16px",
                      display: "flex",
                      gap: 5,
                    }}
                  >
                    <CardMedia
                      component="img"
                      height={isPC ? "50" : "250"}
                      image={product.image}
                      alt={product.name}
                      sx={{ width: isPC ? "15%" : "15%", borderRadius: "16px" }}
                    />
                    <ListItemText
                      primary={`${product.name} ${product.priceUSD} ${product.currency}`}
                      secondary={product.provider}
                    />
                    <Flag country={product.countryCode} />
                  </ListItem>
                ))
              )}
            </List>
          </Paper>
        )}
      </form>
    </ClickAwayListener>
  );
};

export default SearchBar;
