import React, { useEffect, useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import { fetchImages, imagesURL, useWindowSize } from "../../../utils";
import { useNavigate } from "react-router-dom";

export const Footer: React.FC = () => {
  const { isPC, isTablet } = useWindowSize();
  const [images, setImages] = useState<imagesURL[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getImages = async () => {
      try {
        const imagesFetched = await fetchImages(`/public/images/footer`);

        setImages(imagesFetched);
      } catch (error) {
        console.error("Failed to fetch slideshow images", error);
      }
    };
    getImages();
  }, []);
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#104b7b",
        color: "white",
        padding: "20px 0",
        textAlign: "center",
        width: "100%",
        boxSizing: "border-box",
        overflowX: "hidden",
      }}
      display={"flex"}
      flexDirection={isPC || isTablet ? "column" : "column"}
      justifyContent={isPC || isTablet ? "space-between" : "flex-start"}
    >
      <Box
        display="flex"
        justifyContent={isPC || isTablet ? "space-between" : "flex-start"}
        flexDirection={isPC || isTablet ? "row" : "column"}
        alignItems={isPC || isTablet ? "center" : "flex-start"}
        width={isPC || isTablet ? "100%" : "100%"}
        sx={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px", gap: 4 }}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={require("../../../assets/Bsb.png")} // Replace with your logo path
            alt="Logo"
            style={{ width: "150px", height: "auto" }}
          />
          <Box display="flex" justifyContent="flex-start" mt={2}>
            <a
              href="mailto:bsb243.lshi@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <EmailIcon sx={{ marginRight: 1 }} />
            </a>
            <a
              href="https://www.instagram.com/bsb.243/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon sx={{ marginRight: 1 }} />
            </a>
            <a
              href="https://wa.me/+48510347644"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppIcon sx={{ marginRight: 1 }} />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100070906878421"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookIcon />
            </a>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            height: isPC || isTablet ? "200px" : null,
          }}
        >
          <div onClick={() => navigate("/about")}>
            <Typography variant="h6"> <div className='cursor-pointer' onClick={()=>navigate('/about')}>About</div></Typography>
          </div>

          <Typography variant="h6"><div className='cursor-pointer' onClick={()=>navigate('/Contacts')}>Contact</div></Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant="h6">Faites votre achat chez nous</Typography>
          <Typography variant="body2">
            Il vous sera possible de payer avec les methodes suivantes
          </Typography>
          {images.length > 0 && (
            <img
              src={images[0].url}
              alt="Payment Methods"
              style={{ marginTop: "10px", width: "100%", height: "auto" }}
            />
          )}
        </Box>
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          color: "black",
          padding: "10px 0",
          mt: 4,
        }}
      >
        <Typography variant="body2">
          © 2024 Brother Same Business All Rights Reserved. |{" "}
          <Link
            href="https://termify.io/privacy-policy/1Zqcow40ea"
            underline="hover"
          >
            Privacy Policy
          </Link>{" "}
          |{" "}
          <Link
            href="https://termify.io/terms-and-conditions/3aprKAyl8I"
            underline="hover"
          >
            Terms
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};
