import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  TablePagination,
} from "@mui/material";
import {
  formatDate,
  getProductDetails,
  getProductPrice,
  getProducts,
  getUserDetails,
  ProductType,
  TransactionType,
} from "../../utils";
import { useAuth } from "../../utils/context/authContext";

export const KeyLibrarySection: React.FC = () => {
  const { user } = useAuth();
  const [products, setProducts] = useState<ProductType[]>([]);
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<keyof TransactionType>("timestamp");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!user) return;
      const transactionsList = await getUserDetails(
        user.userID,
        "transactions"
      );
      setTransactions(transactionsList || []);
    };
    fetchTransactions();
  }, [user]);
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const products = await getProducts();
    setProducts(products);
  };
  const handleRequestSort = (property: keyof TransactionType) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedTransactions = transactions.sort((a, b) => {
    if (order === "asc") {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    }
    return a[orderBy] > b[orderBy] ? -1 : 1;
  });

  return (
    <Box p={3} width={"100%"} sx={{ width: "100%", padding: 0 }}>
      <Typography variant="h5">Bibliothèque de clés</Typography>
      <Divider sx={{ my: 2 }} />
      <TableContainer component={Paper} sx={{ width: "100%", margin: 0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sortDirection={orderBy === "timestamp" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "timestamp"}
                  direction={orderBy === "timestamp" ? order : "asc"}
                  onClick={() => handleRequestSort("timestamp")}
                >
                  Timestamp
                </TableSortLabel>
              </TableCell>
              <TableCell
                sortDirection={orderBy === "productId" ? order : false}
              >
                <TableSortLabel
                  active={orderBy === "productId"}
                  direction={orderBy === "productId" ? order : "asc"}
                  onClick={() => handleRequestSort("productId")}
                >
                  Produit
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === "code" ? order : false}>
                <TableSortLabel
                  active={orderBy === "code"}
                  direction={orderBy === "code" ? order : "asc"}
                  onClick={() => handleRequestSort("code")}
                >
                  Code
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === "amount" ? order : false}>
                <TableSortLabel
                  active={orderBy === "amount"}
                  direction={orderBy === "amount" ? order : "asc"}
                  onClick={() => handleRequestSort("amount")}
                >
                  Prix
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={orderBy === "country" ? order : false}>
                <TableSortLabel
                  active={orderBy === "country"}
                  direction={orderBy === "country" ? order : "asc"}
                  onClick={() => handleRequestSort("country")}
                >
                  Pays
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedTransactions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{formatDate(transaction.timestamp)}</TableCell>
                  <TableCell>
                    {getProductDetails(transaction.productId, products)}
                  </TableCell>
                  <TableCell>{transaction.code}</TableCell>
                  <TableCell>
                    {getProductPrice(transaction.productId, products)}
                  </TableCell>

                  <TableCell>{transaction.country}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[ 25,50,100]}
          component="div"
          count={transactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};