import { apiSrcUrl } from "../ApiRoutes";

export const fetchPageContent = async (pageName: string) => {
    try {
      const response = await fetch(`${apiSrcUrl}/public/pages${pageName}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch page content: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching page content:", error);
      return { success: false, message: "Error fetching page content" };
    }
  };