import React from 'react';
import { Snackbar, Alert } from '@mui/material';

interface CustomSnackbarProps {
  open: boolean;
  message: string;
  isSuccess: boolean;
  handleClose: () => void;
}

export const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, message, isSuccess, handleClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={isSuccess ? 'success' : 'error'} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};


