import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { AccountSection } from "./AccountSection";
import { BalanceSection } from "./BalanceSection";
import { KeyLibrarySection } from "./KeyLibrarySection";
import { SettingsSection } from "./SettingsSection";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export const AccountTabs: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split("/").pop();
  const tabValue =
    path === "balance"
      ? 1
      : path === "key-library"
      ? 2
      : path === "settings"
      ? 3
      : 0;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        navigate("/account");
        break;
      case 1:
        navigate("/account/balance");
        break;
      case 2:
        navigate("/account/keys");
        break;
      case 3:
        navigate("/account/settings");
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={tabValue} onChange={handleChange} aria-label="account tabs">
        <Tab label="Mon compte" {...a11yProps(0)} />
        <Tab label="Solde" {...a11yProps(1)} />
        <Tab label="Bibliothèque de clés" {...a11yProps(2)} />
        <Tab label="Paramètres" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <AccountSection />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
      <BalanceSection />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
      <KeyLibrarySection />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
      <SettingsSection />
      </TabPanel>
    </Box>
  );
};