import React from "react";
import logo from "../../assets/Bsb.png";
import { useNavigate } from "react-router-dom";

export const FinishSignUp: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div className="flex h-screen justify-center items-center">
      <div className="text-center">
        <img src={logo} alt="Logo" className="w-1/3 mx-auto mb-4" />
        <h2 className="text-2xl font-bold mb-2">Inscription réussie</h2>
        <p className="text-lg">
          Veuillez vérifier votre boîte mail pour finaliser l'inscription.
        </p>
        <button className="border-4 h-16 w-64 m-5 rounded-lg bg-primary text-white text-3xl" onClick={()=>navigate('/login')}>
          se connecter
        </button>
      </div>
    </div>
  );
};
