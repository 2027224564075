import { useUtility } from "../../utils/context/UtilityContext";
import { Header } from "../shared";
import { useNavigate } from "react-router-dom";

export const Manager = () => {
  const navigate = useNavigate();
  const { marginBottomHeader } = useUtility();

  return (
    <div className={`${marginBottomHeader} p-4`}>
      <Header />
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 w-full p-8">
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/product")}
        >
          produit
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/users")}
        >
          users
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/images")}
        >
          images
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/steps")}
        >
          Steps List
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/mottos")}
        >
          Mottos List
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/providers")}
        >
          Providers descriptions
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/giftcard")}
        >
          codes
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/list")}
        >
          predifine list
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/PromoCodeManager")}
        >
          Code Promo
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/rich-text")}
        >
          Rich text pages
        </button>
        <button
          className="h-16 border-4 bg-primary text-white text-lg md:text-xl lg:text-2xl"
          onClick={() => navigate("/manager/sms")}
        >
          SMS
        </button>
      </div>
    </div>
  );
};
