import { apiSrcUrl } from "../ApiRoutes";

export const fetchImages = async (routes:string) => {
    try {
      const response = await fetch(`${apiSrcUrl}${routes}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch slideshow images');
      }
      return await response.json();
    } catch (error) {
      console.error('Failed to fetch slideshow images', error);
      throw error;
    }
  };
  