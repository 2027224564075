import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ProductCardProps, SetFlag } from "../../../utils";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useUtility } from "../../../utils/context/UtilityContext";
import { useNavigate, useParams } from "react-router-dom";
import { BSBCoin } from "../BSBCoin";
const circleVariants = {
  hidden: { scale: 0 },
  visible: { scale: 1 },
};

export const Product: React.FC<ProductCardProps> = ({ product }) => {
  const { provider } = useParams();
  const { addToCart } = useUtility();
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/provider/${product.provider}`);
  };
  const handleAddToCart = (e: React.MouseEvent) => {
    e.stopPropagation();
    addToCart(product);
  };

  useEffect(() => {
    if (provider === product.provider) {
      controls.start("visible");
    } else if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [inView, controls, provider, product.provider]);

  return (
    <motion.div
      ref={ref}
      variants={circleVariants}
      initial="hidden"
      animate={controls}
      transition={{ duration: 1, ease: "easeInOut" }}
      onClick={handleNavigate}
      className="cursor-pointer"
    >
      <Card sx={{ maxWidth: 200, margin: 2, padding: 1 }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography variant="subtitle2" color="textSecondary" noWrap={true}>
              {product.category}
            </Typography>
            <SetFlag
              countryCode={product.countryCode}
              countryName={product.country}
            />
          </Box>
          <CardMedia
            component="img"
            image={product.image}
            alt={product.name}
            sx={{ height: 140, objectFit: "contain", margin: "10px 0" }}
          />
          <Typography
            variant="h6"
            component="div"
            textAlign={"center"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"4px"}
          >
            {product.priceUSD} BSB coins <BSBCoin />
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body2" color="textSecondary">
              {product.name} {product.price} {product.currency}
            </Typography>
            <IconButton color="primary" onClick={handleAddToCart}>
              <ShoppingCartIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    </motion.div>
  );
};
