import React from "react";
import { Footer, Header } from "components/shared";
import { useUtility } from "utils/context/UtilityContext";
import { Typography, Link, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const DataDeletionInstructions: React.FC = () => {
  const { marginBottomHeader } = useUtility();
  const navigation = useNavigate();
  return (
    <div>
      <Header secondPart={false} />
      <Box
        className={`${marginBottomHeader} p-8 bg-gray-50 rounded-lg shadow-md max-w-4xl mx-auto gap-8 flex flex-col w-full h-full justify-center items-left`}
      >
        <Typography
          variant="h4"
          component="h1"
          className="font-bold text-gray-800 mb-6"
        >
          Instructions pour la Suppression des Données
        </Typography>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Conformément à notre politique de confidentialité et aux exigences de
          la législation sur la protection des données, vous avez le droit de
          demander la suppression de vos données personnelles à tout moment.
        </Typography>
        <Typography
          variant="h6"
          component="h2"
          className="font-semibold text-gray-800 mb-4"
        >
          Comment supprimer vos données :
        </Typography>
        <ol className="list-decimal list-inside space-y-2 mb-4">
          <li>Connectez-vous à votre compte sur notre site web.</li>
          <li>Accédez à la page de gestion de votre compte.</li>
          <li>
            Recherchez l'option "Supprimer mon compte" ou "Demander la
            suppression des données".
          </li>
          <li>Cliquez sur l'option et suivez les instructions à l'écran.</li>
          <li>
            Une fois la suppression demandée, vous recevrez un e-mail de
            confirmation.
          </li>
          <li>
            Veuillez noter que certaines données peuvent être conservées
            conformément aux obligations légales ou réglementaires.
          </li>
        </ol>
        <Button onClick={()=>navigation('/account/settings')} sx={{backgroundColor:"red", color:'white'}}> Supprimer mon compte</Button>
        <Typography variant="body1" className="text-gray-700 mb-4">
          Si vous avez des questions ou des préoccupations concernant la
          suppression de vos données, n'hésitez pas à contacter notre support
          client à l'adresse{" "}
          <Link href="mailto:BSB243.lshi@gmail.com" color="primary">
            support
          </Link>
          .
        </Typography>
        <Typography variant="body1" className="text-gray-700">
          Merci d'avoir utilisé notre service.
        </Typography>
      </Box>
      <Footer />
    </div>
  );
};
