import { useAuth } from "../../utils/context";
import { Navigate } from "react-router-dom";

export const Dashboard: React.FC = () => {
  const { role } = useAuth();

  if (role !== 'admin') {
    return <Navigate to="/not-authorized" />;
  }

  return <div>DashBoard</div>;
};
