import React, { useState } from "react";
import { RichTextEditor } from "./RichTextEditor";
import { DisplayContent } from "./DisplayContent";
import { ContentPartType, useGetTables } from "../../../../utils";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { Footer, Header } from "components/shared";
import { motion } from "framer-motion";
import { useUtility } from "utils/context/UtilityContext";



export const RichText = () => {
  const [selectedTable, setSelectedTable] = useState("");
  const [newTable, setNewTable] = useState("");
  const [contentParts, setContentParts] = useState<ContentPartType[]>([]);
  const { tables, loading, error, addTable } = useGetTables();
  const { marginBottomHeader } = useUtility();

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTable(event.target.value as string);
  };

  const handleNewTableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewTable(event.target.value);
  };

  const handleAddNewTable = () => {
    if (newTable && !tables.includes(newTable)) {
      addTable(newTable);
      setSelectedTable(newTable);
      setNewTable("");
      setContentParts([]); // Reset content parts for the new table
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${marginBottomHeader} flex flex-col items-center justify-start gap-4 overflow-x-hidden fancy-scroll w-full`}
    >
      <Header />
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div className={`w-full flex p-8 flex-col gap-4 justify-start items-center`}>
        <div className="flex  flex-col">
          <Typography variant="h6">Choose the page to modify or add</Typography>
          <FormControl margin="dense" fullWidth>
            <InputLabel>Catégorie</InputLabel>
            <Select
              name="category"
              value={selectedTable}
              onChange={handleSelectChange as any}
            >
              {tables.map((table: string, index: number) => (
                <MenuItem key={index} value={table}>
                  {table}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Add New Category"
            value={newTable}
            onChange={handleNewTableChange}
            fullWidth
            margin="dense"
          />
          <Button
            onClick={handleAddNewTable}
            variant="contained"
            color="primary"
            disabled={!newTable}
          >
            Add Category
          </Button>
        </div>

        <h1>Rich Text Editor</h1>
        {selectedTable && (
          <>
            <RichTextEditor
              table={selectedTable}
              contentParts={contentParts}
              setContentParts={setContentParts}
            />
            <DisplayContent table={selectedTable} contentParts={contentParts} />
          </>
        )}
      </div>
      <Footer />
    </motion.div>
  );
};