import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  ExpandLess,
  ExpandMore,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { Footer, Header } from "../shared";
import { QAType, ProviderType } from "../../utils/types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fetchPublic, rolesAdmin, useSecurityCheck } from "../../utils";
import { motion } from "framer-motion";
import { CustomSnackbar } from "../shared";
import { putInfo } from "utils/api/private/Info";

export const ProviderManager = () => {
  const [providers, setProviders] = useState<ProviderType[]>([]);
  const securityCheck = useSecurityCheck(rolesAdmin);

  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentQA, setCurrentQA] = useState<QAType | null>(null);
  const [expandedQA, setExpandedQA] = useState<string | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<ProviderType | null>(
    null
  );
  const [editDescription, setEditDescription] = useState<string>("");
  const [editImage, setEditImage] = useState<string>("");
  const [editVideoUrl, setEditVideoUrl] = useState<string>("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSuccess, setSnackbarSuccess] = useState(true);

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProviders = async () => {
    setLoading(true);

    const result = await fetchPublic("/public/info/providers");
    if (result.info) {
      const providersList = Object.keys(result.info).map((key) => ({
        id: key,
        ...result.info[key],
        qas: result.info[key].qas || [], // Initialize qas to an empty array if undefined
      }));
      setProviders(providersList);
    }
    setLoading(false);
  };

  const handleSaveQA = async (qa: QAType) => {
    if (!selectedProvider) return;

    const updatedQAs = selectedProvider.qas ? [...selectedProvider.qas] : [];

    if (qa.id) {
      const index = updatedQAs.findIndex((q) => q.id === qa.id);
      updatedQAs[index] = qa;
    } else {
      const newQA = { ...qa, id: `qa_${Date.now()}` }; // Generate a unique ID
      updatedQAs.push(newQA);
    }

    const providerData = {
      ...selectedProvider,
      qas: updatedQAs,
    };

    await putInfo(providerData, "providers");
    fetchProviders();
    setOpenDialog(false);
  };

  const handleDeleteQA = async (id: string) => {
    if (!selectedProvider) return;

    const updatedQAs = selectedProvider.qas.filter((qa) => qa.id !== id);

    const providerData = {
      ...selectedProvider,
      qas: updatedQAs,
    };

    await putInfo(providerData, "providers");
    fetchProviders();
  };

  const handleExpandQA = (qa: string) => {
    setExpandedQA(expandedQA === qa ? null : qa);
  };

  const handleSaveProviderDetails = async () => {
    setLoading(true);
    try {
      if (!selectedProvider) return;

      const providerData = {
        name: selectedProvider.name,
        description: editDescription,
        url: editImage,
        videoUrl: editVideoUrl,
        qas: selectedProvider.qas,
      };

      await putInfo(
        { ...providerData, id: selectedProvider.name },
        "providers"
      );

      setSnackbarMessage("Détails du fournisseur sauvegardés avec succès!");
      setSnackbarSuccess(true);
    } catch (error) {
      setSnackbarMessage(
        "Erreur lors de la sauvegarde des détails du fournisseur"
      );
      setSnackbarSuccess(false);
    } finally {
      fetchProviders();
      setSnackbarOpen(true);
      setLoading(false);
    }
  };

  const handleSelectProvider = (provider: ProviderType) => {
    if (selectedProvider?.id === provider.id) {
      // Deselect the provider if it's already selected
      setSelectedProvider(null);
      setEditDescription("");
      setEditImage("");
      setEditVideoUrl("");
    } else {
      setSelectedProvider(null); // Clear previous selection first
      setTimeout(() => {
        setSelectedProvider(provider);
        setEditDescription(provider.description);
        setEditImage(provider.url);
        if (provider.videoUrl) setEditVideoUrl(provider.videoUrl);
        setExpandedQA(null); // Reset expanded QA
      }, 0); // Delay to ensure the state is reset before setting new provider
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (securityCheck) {
    return securityCheck;
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="400px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`flex flex-col items-center justify-between gap-4 overflow-x-hidden fancy-scroll min-h-screen`}
    >
      <Box sx={{ width: "100%", paddingLeft: "10%", paddingRight: "10%" }}>
        <Header />
        <Typography variant="h4" gutterBottom marginTop={"10%"}>
          Gestion des Fournisseurs
        </Typography>
        <List>
          {providers.map((provider) => (
            <div key={provider.id}>
              <ListItem button onClick={() => handleSelectProvider(provider)}>
                <ListItemText primary={provider.name} />
              </ListItem>
            </div>
          ))}
        </List>
        {selectedProvider && (
          <>
            <Typography variant="h5" gutterBottom>
              {selectedProvider.name}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Description:
            </Typography>
            <ReactQuill value={editDescription} onChange={setEditDescription} />
            <TextField
              label="URL de l'image"
              value={editImage}
              onChange={(e) => setEditImage(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="URL de l'youtube"
              value={editVideoUrl}
              onChange={(e) => setEditVideoUrl(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveProviderDetails}
            >
              Sauvegarder les détails du fournisseur
            </Button>
            <Button
              sx={{ marginLeft: "5px" }}
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              Ajouter une nouvelle Q&A
            </Button>
            <List>
              {selectedProvider.qas.map((qa) => (
                <div key={qa.id}>
                  <ListItem button onClick={() => handleExpandQA(qa.question)}>
                    <ListItemText primary={qa.question} />
                    {expandedQA === qa.question ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => {
                          setCurrentQA(qa);
                          setOpenDialog(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        edge="end"
                        onClick={() => handleDeleteQA(qa.id!)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Collapse
                    in={expandedQA === qa.question}
                    timeout="auto"
                    unmountOnExit
                  >
                    <Box sx={{ marginLeft: 4 }}>
                      <Typography variant="body2" noWrap>
                        {qa.answer}
                      </Typography>
                    </Box>
                  </Collapse>
                </div>
              ))}
            </List>
            <QADialog
              open={openDialog}
              onClose={() => {
                setOpenDialog(false);
                setCurrentQA(null);
              }}
              onSave={handleSaveQA}
              initialData={currentQA}
            />
          </>
        )}
      </Box>
      <Footer />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        isSuccess={snackbarSuccess}
        handleClose={handleSnackbarClose}
      />
    </motion.div>
  );
};

interface QADialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (qa: QAType) => void;
  initialData?: QAType | null;
}

const QADialog: React.FC<QADialogProps> = ({
  open,
  onClose,
  onSave,
  initialData,
}) => {
  const [qa, setQA] = useState<QAType>(
    initialData || { question: "", answer: "" }
  );

  useEffect(() => {
    if (initialData) {
      setQA(initialData);
    } else {
      setQA({ question: "", answer: "" });
    }
  }, [initialData]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setQA({ ...qa, [name]: value });
  };

  const handleSave = () => {
    onSave(qa);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{qa.id ? "Modifier Q&A" : "Ajouter Q&A"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Question"
          name="question"
          value={qa.question}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <ReactQuill
          value={qa.answer}
          onChange={(value) => setQA({ ...qa, answer: value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button onClick={handleSave} color="primary">
          Sauvegarder
        </Button>
      </DialogActions>
    </Dialog>
  );
};
