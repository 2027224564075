import React from 'react';

interface TextDisplayProps {
    text: string;
    color?: string;
    fontSize?: string;
    fontFamily?: string;
    width?: string;
    textAlign?: "left" | "center" | "right" | "justify";
    lineHeight?: string;
    letterSpacing?: string;
    fontWeight?: "normal" | "bold" | "bolder" | "lighter";
}

export const TextDisplay: React.FC<TextDisplayProps> = ({
  text,
  color = 'black',
  fontSize = '16px',
  fontFamily = 'Arial, sans-serif',
  width = '100%',
  textAlign = "left",
  lineHeight = "1.5",
  letterSpacing = "normal",
  fontWeight = "normal"
}) => {
  return (
    <div
      style={{ 
        color, 
        fontSize, 
        fontFamily, 
        width, 
        textAlign, 
        lineHeight, 
        letterSpacing, 
        fontWeight 
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
