import React from 'react';
import { Grid } from '@mui/material';

import { Product } from './Product';
import { ProductType } from '../../../utils';

interface ProductListProps {
    sampleProducts: ProductType[];
  }
export const ProductList: React.FC<ProductListProps> = ({sampleProducts}) => {

  return (
      <Grid container justifyContent="center" sx={{backgroundColor:"#f5f5f6"}}>
        {sampleProducts.map((product, index) => (
          <Product key={index} product={product} />
        ))}
      </Grid>
    );
  };
  